/* eslint-disable dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  CForm,
  CRow,
  CCol,
  CFormInput,
  CButton,
  CFormSelect,
} from '@coreui/react';
// SERVICES
import FeedsService from 'src/services/api/FeedsService';
import AppBaseDetail from 'src/components/ui/Detail/AppBaseDetail';
import AppLoadingSpinner from 'src/components/ui/AppLoadingSpinner';
import TranslationStatus from 'src/components/ui/TranslationStatus/TranslationStatus';
// Utils
import { LANGUAGE_OPTIONS } from 'src/utils/translationUtils';
import useTranslationBatch from 'src/hooks/useTranslationBatch';
import TranslationBatchModal from 'src/components/ui/TranslationBatchModal';

const FeedsDetail = () => {
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    reset,
    getValues,
  } = useForm({
    defaultValues: {
      code: '',
      translations: {
        it: { name: '' },
      },
    },
  });
  const [state, setState] = useState({
    loading: true,
    model: null,
  });
  const [currentLanguage, setCurrentLanguage] = useState('it');

  const feedsService = new FeedsService();

  const {
    translationModal,
    selectedLanguages,
    setSelectedLanguages,
    handleTranslate,
    executeTranslation,
    closeTranslationModal,
    resetSessionTranslations,
  } = useTranslationBatch({
    type: 'feeds',
    originalModel: state.model,
    batchSize: 5,
    onTranslationComplete: (translations) => {
      const currentFormValues = getValues();
      currentFormValues.translations = translations;
      reset(currentFormValues);
    },
  });

  const onSubmit = (data) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({ loading: false, model: { ...response.data } });
        // Reset delle traduzioni in memoria dopo il salvataggio riuscito
        resetSessionTranslations();
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error });
        reject();
      };

      // Prepariamo i dati da salvare
      const dataToSave = {
        ...data,
        name: data.translations?.it?.name, // Il campo principale prende il valore italiano
        translations: data.translations, // Manteniamo tutte le traduzioni
      };

      feedsService.updateItem(state.model['_id'], dataToSave, okEditCallback, koEditCallback);
    });

    toast.promise(savePromise, {
      loading: 'Attendere, salvando le modifiche...',
      success: 'Dato modificato con successo!',
      error: 'Ops, si è verificato un errore!',
    }, {
      success: {
        duration: 5000,
      },
      error: {
        duration: 5000,
      },
    });
  };

  const handleReset = () => {
    reset({
      code: state.model?.code,
      translations: state.model?.translations,
    });
  };

  useEffect(() => {
    if (id && state.loading) {
      const okGetCallback = (response) => {
        const responseData = { ...response.data };
        const modelData = {
          ...responseData,
          translations: responseData.translations || {
            it: { name: responseData.name || '' },
          },
        };

        // Create empty structures for all supported languages
        const supportedLanguages = LANGUAGE_OPTIONS.map((option) => option.value);
        const completeTranslations = { ...modelData.translations };

        // Ensure all languages have at least empty structures
        supportedLanguages.forEach((lang) => {
          if (!completeTranslations[lang]) {
            completeTranslations[lang] = { name: '' };
          }
        });

        // Update the model with complete translations
        modelData.translations = completeTranslations;

        reset({
          code: responseData.code,
          translations: completeTranslations,
        });

        setState({ loading: false, model: modelData });
      };

      const koGetCallback = (error) => {
        const errorMessage = error?.data?.message || 'Nessun errore';
        setState({ loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      feedsService.getItem(id, okGetCallback, koGetCallback);
    } else if (state.model && !state.loading) {
      const currentValues = getValues();
      reset({
        ...currentValues,
        translations: currentValues.translations,
      });
    }
  }, [id, currentLanguage, state.loading, state.model]);

  if (state.loading === true) return <AppLoadingSpinner />;

  return (
    <AppBaseDetail
      type="feed"
      saveAction={handleSubmit(onSubmit)}
      resetAction={handleReset}
    >
      <section id="feeds-detail">
        <CFormSelect
          className="mb-4"
          value={currentLanguage}
          onChange={(e) => setCurrentLanguage(e.target.value)}
          options={LANGUAGE_OPTIONS}
          label="Lingua"
        />
        <div className="d-flex justify-content-start align-items-center mb-4 gap-5">
          {currentLanguage === 'it' && (
            <CButton
              color="primary"
              size="sm"
              onClick={() => handleTranslate({
                name: getValues('translations.it.name'),
              })}
            >
              Traduci automaticamente
            </CButton>
          )}
          <TranslationStatus
            translations={getValues('translations')}
            fields={['name']}
          />
        </div>
        <CForm
          className="row g-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <CRow>
            <CCol md={6}>
              <Controller
                name={`translations.${currentLanguage}.name`}
                control={control}
                defaultValue={state?.model?.translations?.[currentLanguage]?.name || ''}
                render={({ field }) => (
                  <CFormInput
                    label="Nome"
                    {...field}
                  />
                )}
              />
            </CCol>
            <div className="mb-3" />
          </CRow>
          <CRow>
            <CCol md={6}>
              <Controller
                name="code"
                control={control}
                defaultValue={state?.model?.code}
                render={({ field }) => (
                  <CFormInput
                    label="Codice"
                    {...field}
                  />
                )}
              />
            </CCol>
          </CRow>
        </CForm>
        <TranslationBatchModal
          show={translationModal.show}
          selectedLanguages={selectedLanguages}
          onLanguageSelect={setSelectedLanguages}
          onClose={closeTranslationModal}
          onStartTranslation={executeTranslation}
          batchProgress={translationModal.progress}
          currentBatch={translationModal.currentBatch}
          totalBatches={translationModal.totalBatches}
          translationStatus={translationModal.translationStatus}
          isTranslating={translationModal.isTranslating}
          currentBatchLanguages={translationModal.currentBatchLanguages}
        />
      </section>
    </AppBaseDetail>
  );
};

export default FeedsDetail;
