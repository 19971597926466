/* eslint-disable  */
// React and PropTypes
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// CoreUI Components
import {
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CButton,
  CRow,
  CCol,
  CProgress,
  CProgressBar,
  CCard,
  CCardBody,
  CAlert,
  CBadge,
} from '@coreui/react';

// Utils
import { LANGUAGE_OPTIONS } from 'src/utils/translationUtils';

const TranslationBatchModal = ({
  show,
  onClose,
  selectedLanguages,
  onLanguageSelect,
  onStartTranslation,
  batchProgress,
  currentBatch,
  totalBatches,
  translationStatus,
  isTranslating,
  currentBatchLanguages,
}) => {
  // Filtra le lingue disponibili escludendo l'italiano
  const availableLanguages = LANGUAGE_OPTIONS.filter((lang) => lang.value !== 'it');

  // Stato per gestire la selezione iniziale (specifiche lingue o tutte)
  const [selectionMode, setSelectionMode] = useState(null);

  // Verifica se tutte le lingue sono selezionate
  const areAllSelected = availableLanguages.every((lang) => selectedLanguages.includes(lang.value));

  // Reset dello stato quando si chiude la modale
  useEffect(() => {
    if (!show) {
      setSelectionMode(null);
    }
  }, [show]);

  // Gestisce il click sul pulsante "Seleziona tutte"
  const handleSelectAll = () => {
    if (areAllSelected) {
      // Se tutte le lingue sono selezionate, le deseleziona
      onLanguageSelect([]);
    } else {
      // Altrimenti seleziona tutte le lingue disponibili
      onLanguageSelect(availableLanguages.map((lang) => lang.value));
    }
  };

  // Renderizza la schermata di selezione delle lingue
  const renderLanguageSelection = () => (
    <>
      <CAlert color="info">
        <h5>Seleziona le lingue da tradurre</h5>
        <p>Seleziona una o più lingue per avviare la traduzione automatica dei tuoi contenuti. Il processo avverrà in batch per garantire traduzioni di qualità.</p>
      </CAlert>

      <CRow className="mb-4">
        <CCol xs={12}>
          <CButton
            color={areAllSelected ? 'danger' : 'primary'}
            className="w-100"
            onClick={handleSelectAll}
          >
            {areAllSelected ? (
              <>
                <i className="fas fa-times-circle me-2" />
                Deseleziona tutte le lingue
              </>
            ) : (
              <>
                <i className="fas fa-check-circle me-2" />
                Seleziona tutte le lingue
              </>
            )}
          </CButton>
        </CCol>
      </CRow>
      
      <CRow className="language-checkboxes-container">
        {availableLanguages.map((lang) => (
          <CCol key={lang.value} xs={6} sm={6} md={4} className="mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                id={`lang-${lang.value}`}
                checked={selectedLanguages.includes(lang.value)}
                onChange={(e) => {
                  if (e.target.checked) {
                    onLanguageSelect([...selectedLanguages, lang.value]);
                  } else {
                    onLanguageSelect(selectedLanguages.filter((l) => l !== lang.value));
                  }
                }}
              />
              <label className="form-check-label" htmlFor={`lang-${lang.value}`}>
                {lang.label}
              </label>
            </div>
          </CCol>
        ))}
      </CRow>
    </>
  );

  // Renderizza lo stato del progresso della traduzione
  const renderTranslationProgress = () => (
    <>
      <CAlert color="info">
        <h5>Traduzione in corso</h5>
        <p>Stiamo traducendo i tuoi contenuti in batch. Questo processo potrebbe richiedere alcuni minuti. Non chiudere questa finestra.</p>
      </CAlert>
      
      <CCard className="mb-4">
        <CCardBody>
          <h6>Progresso totale: {batchProgress}%</h6>
          <CProgress className="mb-3">
            <CProgressBar value={batchProgress} />
          </CProgress>
          
          <div className="d-flex justify-content-between mb-3">
            <span>Batch corrente: {currentBatch} di {totalBatches}</span>
            <span>Lingue in traduzione:</span>
          </div>
          
          <div className="d-flex flex-wrap mb-3 gap-2">
            {currentBatchLanguages.map((langCode) => {
              const lang = LANGUAGE_OPTIONS.find((l) => l.value === langCode);
              return (
                <CBadge key={langCode} color="primary">
                  {lang ? lang.label : langCode}
                </CBadge>
              );
            })}
          </div>
        </CCardBody>
      </CCard>
      
      <CCard>
        <CCardBody>
          <h6>Stato traduzioni</h6>
          <div className="d-flex flex-wrap gap-2">
            {Object.entries(translationStatus).map(([langCode, status]) => {
              const lang = LANGUAGE_OPTIONS.find((l) => l.value === langCode);
              const label = lang ? lang.label : langCode;
              let color = 'secondary';
              let icon = '';
              let statusText = 'In attesa';
              
              if (status === 'success') {
                color = 'success';
                icon = 'fas fa-check-circle me-1';
                statusText = 'Completata';
              } else if (status === 'error') {
                color = 'danger';
                icon = 'fas fa-times-circle me-1';
                statusText = 'Errore';
              } else if (status === 'translating') {
                color = 'warning';
                icon = 'fas fa-spinner fa-spin me-1';
                statusText = 'In corso';
              }
              
              return (
                <CBadge key={langCode} color={color} className="p-2 mb-2">
                  <i className={icon}></i> {label}: {statusText}
                </CBadge>
              );
            })}
          </div>
        </CCardBody>
      </CCard>
    </>
  );

  // Renderizza il contenuto principale della modale
  const renderModalContent = () => {
    // Se stiamo traducendo, mostra il progresso
    if (isTranslating) {
      return renderTranslationProgress();
    }
    
    // Altrimenti mostra la selezione delle lingue
    return renderLanguageSelection();
  };

  return (
    <CModal
      size="lg"
      backdrop="static" 
      visible={show}
      onClose={isTranslating ? undefined : onClose}
      className="translation-batch-modal"
    >
      <CModalHeader onClose={isTranslating ? undefined : onClose}>
        <CModalTitle>Traduzione automatica</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {renderModalContent()}
      </CModalBody>
      <CModalFooter>
        {!isTranslating && (
          <>
            <CButton color="secondary" onClick={onClose}>
              Annulla
            </CButton>
            <CButton 
              color="primary" 
              onClick={onStartTranslation}
              disabled={selectedLanguages.length === 0}
            >
              Traduci
            </CButton>
          </>
        )}
        {isTranslating && (
          <CButton color="secondary" disabled>
            Traduzione in corso...
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );
};

TranslationBatchModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedLanguages: PropTypes.arrayOf(PropTypes.string).isRequired,
  onLanguageSelect: PropTypes.func.isRequired,
  onStartTranslation: PropTypes.func.isRequired,
  batchProgress: PropTypes.number,
  currentBatch: PropTypes.number,
  totalBatches: PropTypes.number,
  translationStatus: PropTypes.object,
  isTranslating: PropTypes.bool,
  currentBatchLanguages: PropTypes.arrayOf(PropTypes.string),
};

TranslationBatchModal.defaultProps = {
  batchProgress: 0,
  currentBatch: 0,
  totalBatches: 0,
  translationStatus: {},
  isTranslating: false,
  currentBatchLanguages: [],
};

export default TranslationBatchModal; 