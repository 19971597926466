/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  CForm,
  CRow,
  CCol,
  CFormInput,
  CFormTextarea,
  CButton,
  CFormSelect,
} from "@coreui/react";
// SERVICES
import RegionsService from "src/services/api/RegionsService";
import AppBaseDetail from "src/components/ui/Detail/AppBaseDetail";
import Gallery from "src/components/ui/Images/Gallery/Gallery";
import AppLoadingSpinner from "src/components/ui/AppLoadingSpinner";
import useTranslationBatch from 'src/hooks/useTranslationBatch';
import TranslationBatchModal from 'src/components/ui/TranslationBatchModal';
import TranslationStatus from 'src/components/ui/TranslationStatus/TranslationStatus';

// Utils
import { LANGUAGE_OPTIONS } from "src/utils/translationUtils";

const RegionsDetail = () => {
  const { id } = useParams();
  const { control, handleSubmit, reset, getValues } = useForm();
  const [state, setState] = useState({
    loading: true,
    model: null,
  });
  const [currentLanguage, setCurrentLanguage] = useState("it");
  const [mediaContents, setMediaContents] = useState([]);

  const regionsService = new RegionsService();

  const {
    translationModal,
    selectedLanguages,
    setSelectedLanguages,
    handleTranslate,
    executeTranslation,
    closeTranslationModal,
    resetSessionTranslations,
  } = useTranslationBatch({
    type: 'product_categories',
    originalModel: state.model,
    batchSize: 2,
    onTranslationComplete: (translations) => {
      const currentFormValues = getValues();
      currentFormValues.translations = translations;
      reset(currentFormValues);
    },
  });

  const onSubmit = (data) => {
    const body = {
      name: data.translations?.it?.name,
      description: data.translations?.it?.description,
      translations: data?.translations,
      meta_title: data?.translations?.it?.meta_title,
      meta_description: data?.translations?.it?.meta_description,
    };
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({ loading: false, model: { ...response.data } });
        resetSessionTranslations();
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error });
        reject();
      };

      regionsService.updateItem(
        state.model["_id"],
        body,
        okEditCallback,
        koEditCallback
      );
    });

    toast.promise(
      savePromise,
      {
        loading: "Attendere, salvando le modifiche...",
        success: "Dato modificato con successo!",
        error: "Ops, si è verificato un errore!",
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      }
    );
  };

  const handleReset = () => {
    reset({ tag: state.model?.tag, code: state.model?.code });
  };

  useEffect(() => {
    // Se abbiamo un ID e stiamo caricando, facciamo la chiamata API
    if (id && state.loading) {
      const okGetCallback = (response) => {
        const regionResponseData = { ...(response?.data || {}) };
        const regionModelData = {};

        regionModelData.translations = regionResponseData?.translations || {
          it: {
            name: regionResponseData?.name || "",
            description: regionResponseData?.description || "",
          },
        };

        // Prepariamo i media contents
        const filteredMedia = regionResponseData.media_contents
          .filter((current) => current.type === "region_image")
          .map((media, index) => ({
            ...media,
            uniqueKey: `${media.child_id}-${media.filename}-${index}`,
          }))
          .sort((a, b) => a.order - b.order);

        // Aggiorniamo tutto lo state in una volta
        reset(regionModelData);
        setMediaContents(filteredMedia);
        setState({ loading: false, model: regionResponseData });
      };

      const koGetCallback = (error) => {
        const errorMessage = error?.data?.message || "Nessun errore";
        setState({ loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      regionsService.getItem(id, okGetCallback, koGetCallback);
    }
    // Se abbiamo un modello e cambia la lingua, aggiorniamo solo il form
    else if (state.model && !state.loading) {
      const currentValues = getValues();
      reset({
        ...currentValues,
        translations: currentValues.translations,
        attributes: currentValues.attributes,
      });
    }
  }, [id, currentLanguage, state.loading, state.model]);

  if (state.loading === true) return <AppLoadingSpinner />;

  if (state.error) return <p>NO DATA</p>;

  return (
    <AppBaseDetail
      type="regione"
      saveAction={handleSubmit(onSubmit)}
      resetAction={handleReset}
    >
      <section id="region-detail">
        <CFormSelect
          className="mb-4"
          value={currentLanguage}
          onChange={(e) => {
            const newLanguage = e.target.value;
            setCurrentLanguage(newLanguage);
            
            // Create empty structure for untranslated language if it doesn't exist
            const currentValues = getValues();
            if (!currentValues.translations[newLanguage]) {
              const emptyTranslation = {
                name: "",
                description: "",
                meta_title: "",
                meta_description: ""
              };
              
              const updatedTranslations = {
                ...currentValues.translations,
                [newLanguage]: emptyTranslation
              };
              
              reset({
                ...currentValues,
                translations: updatedTranslations
              });
            }
          }}
          options={LANGUAGE_OPTIONS}
          label="Lingua"
        />
        <div className="d-flex justify-content-start align-items-center mb-4 gap-5">
          {currentLanguage === "it" && (
            <CButton
              color="primary"
              size="sm"
              onClick={() =>
                handleTranslate({
                  name: getValues("translations.it.name"),
                  description: getValues("translations.it.description"),
                  meta_title: getValues("translations.it.meta_title"),
                  meta_description: getValues("translations.it.meta_description"),
                })
              }
            >
              Traduci automaticamente i contenuti
            </CButton>
          )}
          <TranslationStatus
            translations={getValues('translations')}
            fields={['name', 'description', 'meta_title', 'meta_description']}
          />
        </div>
        <CForm className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <CRow>
            <CCol md={12}>
              <Controller
                name={`translations.${currentLanguage}.name`}
                control={control}
                defaultValue={
                  state?.model?.translations?.[currentLanguage]?.name || ""
                }
                render={({ field }) => (
                  <CFormInput
                    label="Nome Regione"
                    placeholder="Inserisci nome regione"
                    {...field}
                  />
                )}
              />
            </CCol>
            <CCol md={12}>
              <Controller
                name={`translations.${currentLanguage}.description`}
                control={control}
                defaultValue={
                  state?.model?.translations?.[currentLanguage]?.description ||
                  ""
                }
                render={({ field }) => (
                  <CFormTextarea
                    label="Descrizione"
                    placeholder="Inserisci descrizione regione"
                    rows={10}
                    {...field}
                  />
                )}
              />
            </CCol>

            {/* Sezione Meta Dati */}
            <CCol md={12} className="mt-4">
              <h4>Meta Dati SEO</h4>
              <CRow className="g-3 mb-4">
                <CCol md={12}>
                  <Controller
                    name={`translations.${currentLanguage}.meta_title`}
                    control={control}
                    defaultValue={
                      state?.model?.translations?.[currentLanguage]
                        ?.meta_title || ""
                    }
                    render={({ field }) => (
                      <CFormInput
                        label="Titolo"
                        placeholder="Inserisce titolo"
                        id="region-meta_title"
                        {...field}
                      />
                    )}
                  />
                </CCol>
                <CCol md={12}>
                  <Controller
                    name={`translations.${currentLanguage}.meta_description`}
                    control={control}
                    defaultValue={
                      state?.model?.translations?.[currentLanguage]
                        ?.meta_description || ""
                    }
                    render={({ field }) => (
                      <>
                        <CFormTextarea
                          label="Meta descrizione"
                          placeholder="Inserisce qui la meta descrizione"
                          id="region-meta_description"
                          rows="3"
                          {...field}
                        />
                        <small>Si consiglia di non superare i 160 caratteri</small>
                      </>
                    )}
                  />
                </CCol>
              </CRow>
            </CCol>

            <Gallery
              contentId={id}
              contentType="region_image"
              Service={RegionsService}
              title="Galleria regione"
              data={mediaContents}
              onUpdate={(imagesArray) => {
                const updatedMedia = imagesArray.map((media, index) => ({
                  ...media,
                  uniqueKey:
                    media.uniqueKey ||
                    `${media.child_id}-${media.filename}-${index}`,
                }));
                setMediaContents(updatedMedia);
              }}
            />
            <div className="mb-3" />
          </CRow>
        </CForm>
        <TranslationBatchModal
          show={translationModal.show}
          selectedLanguages={selectedLanguages}
          onLanguageSelect={setSelectedLanguages}
          onClose={closeTranslationModal}
          onStartTranslation={executeTranslation}
          batchProgress={translationModal.progress}
          currentBatch={translationModal.currentBatch}
          totalBatches={translationModal.totalBatches}
          translationStatus={translationModal.translationStatus}
          isTranslating={translationModal.isTranslating}
          currentBatchLanguages={translationModal.currentBatchLanguages}
        />
      </section>
    </AppBaseDetail>
  );
};

export default RegionsDetail;
