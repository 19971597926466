/* eslint-disable */
import toast from 'react-hot-toast';
import ApiProxyService from './apiProxyService';

class TranslationService extends ApiProxyService {
  /**
   * Metodo originale per la traduzione dei campi
   */
  translateFields(collectionName, body, okCallback, koCallback) {
    const path = `/${collectionName}/fields-translate`;

    // Mostriamo il toast di loading
    const toastId = toast.loading('Traduzione in corso...');

    return super.postItem(
      path,
      body,
      (response) => {
        // Rimuoviamo il toast di loading e mostriamo il successo
        toast.dismiss(toastId);
        okCallback(response);
      },
      (error) => {
        // Rimuoviamo il toast di loading e mostriamo l'errore
        toast.dismiss(toastId);
        koCallback(error);
      },
    );
  }

  /**
   * Traduce le lingue in batch
   * @param {string} collectionName - Nome della collezione
   * @param {Object} body - Corpo della richiesta (con translations e instructions)
   * @param {Array} languages - Array di lingue da tradurre
   * @param {number} batchSize - Dimensione del batch (default: 4)
   * @param {Function} onProgress - Callback per il progresso
   * @param {Function} onBatchComplete - Callback per il completamento di un batch
   * @param {Function} onComplete - Callback per il completamento totale
   * @param {Function} onError - Callback per l'errore
   */
  translateInBatches(
    collectionName,
    body,
    languages,
    batchSize = 4,
    onProgress,
    onBatchComplete,
    onComplete,
    onError,
  ) {
    // Copiamo il corpo per non modificare l'originale
    const originalBody = { ...body };
    
    // Verifichiamo che ci siano translations e instructions
    if (!originalBody.translations || !originalBody.instructions) {
      onError(new Error('Translations o instructions mancanti nel body'));
      return;
    }

    // Prepariamo i batch di lingue
    const batches = [];
    for (let i = 0; i < languages.length; i += batchSize) {
      batches.push(languages.slice(i, i + batchSize));
    }

    // Stato delle traduzioni
    const translationStatus = {};
    languages.forEach((lang) => {
      translationStatus[lang] = 'pending'; // pending, translating, success, error
    });

    // Aggiorniamo il callback di progresso iniziale
    if (onProgress) {
      onProgress({
        progress: 0,
        currentBatch: 0,
        totalBatches: batches.length,
        currentBatchLanguages: [],
        translationStatus,
      });
    }

    // Funzione per eseguire un batch
    const executeBatch = (batchIndex, updatedTranslations) => {
      if (batchIndex >= batches.length) {
        // Tutti i batch sono stati completati
        onComplete(updatedTranslations);
        return;
      }

      const currentBatchLanguages = batches[batchIndex];
      const currentProgress = Math.round((batchIndex / batches.length) * 100);

      // Aggiorniamo lo stato delle lingue di questo batch a "translating"
      currentBatchLanguages.forEach((lang) => {
        translationStatus[lang] = 'translating';
      });

      // Aggiorniamo il callback di progresso
      if (onProgress) {
        onProgress({
          progress: currentProgress,
          currentBatch: batchIndex + 1,
          totalBatches: batches.length,
          currentBatchLanguages,
          translationStatus,
        });
      }

      // Creiamo il body per questo batch
      const batchBody = {
        ...originalBody,
        translations: updatedTranslations || originalBody.translations,
        languages: currentBatchLanguages,
      };

      // Eseguiamo la chiamata API per questo batch
      this.translateFields(
        collectionName,
        batchBody,
        (response) => {
          // Traduzioni completate per questo batch
          const newTranslations = response.data.translations;

          // Aggiorniamo lo stato delle lingue a "success"
          currentBatchLanguages.forEach((lang) => {
            translationStatus[lang] = 'success';
          });

          // Chiamiamo il callback per il completamento del batch
          if (onBatchComplete) {
            onBatchComplete(batchIndex, newTranslations);
          }

          // Aggiorniamo il callback di progresso
          if (onProgress) {
            onProgress({
              progress: Math.round(((batchIndex + 1) / batches.length) * 100),
              currentBatch: batchIndex + 1,
              totalBatches: batches.length,
              currentBatchLanguages: [],
              translationStatus,
            });
          }

          // Procediamo con il prossimo batch
          executeBatch(batchIndex + 1, newTranslations);
        },
        (error) => {
          console.error(`Errore nel batch ${batchIndex + 1}:`, error);

          // Aggiorniamo lo stato delle lingue a "error"
          currentBatchLanguages.forEach((lang) => {
            translationStatus[lang] = 'error';
          });

          // Se il batch fallisce, proviamo a ridurre la dimensione del batch
          if (batchSize > 1 && currentBatchLanguages.length > 1) {
            // Dividiamo questo batch in batch più piccoli
            const smallerBatchSize = Math.max(1, Math.floor(batchSize / 2));
            const newBatches = [];
            
            for (let i = 0; i < currentBatchLanguages.length; i += smallerBatchSize) {
              newBatches.push(currentBatchLanguages.slice(i, i + smallerBatchSize));
            }
            
            // Sostituiamo il batch corrente con i nuovi batch più piccoli
            batches.splice(batchIndex, 1, ...newBatches);
            
            // Resettiamo lo stato delle lingue che erano in "error" a "pending"
            currentBatchLanguages.forEach((lang) => {
              translationStatus[lang] = 'pending';
            });

            // Aggiorniamo il callback di progresso
            if (onProgress) {
              onProgress({
                progress: currentProgress,
                currentBatch: batchIndex + 1,
                totalBatches: batches.length,
                currentBatchLanguages: [],
                translationStatus,
              });
            }

            // Riproviamo con il batch corrente (che ora è il primo batch più piccolo)
            executeBatch(batchIndex, updatedTranslations);
          } else {
            // Se non possiamo ridurre ulteriormente il batch, segnaliamo l'errore ma continuiamo con il prossimo batch
            if (onError) {
              onError(error, batchIndex);
            }

            // Procediamo con il prossimo batch
            executeBatch(batchIndex + 1, updatedTranslations);
          }
        },
      );
    };

    // Iniziamo con il primo batch
    executeBatch(0, originalBody.translations);
  }
}

const translationService = new TranslationService();
export default translationService;
