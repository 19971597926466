/* eslint-disable */
// React
import React, { useEffect, useState, useMemo } from "react";

// React Select
import AsyncSelect from "react-select/async";

// React Hook Form
import { useForm, Controller } from "react-hook-form";

// PropTypes
import PropTypes from "prop-types";

// CoreUI Components
import {
  CCol,
  CForm,
  CFormInput,
  CFormLabel,
  CFormSwitch,
  CFormTextarea,
  CInputGroup,
  CInputGroupText,
  CRow,
} from "@coreui/react";

// Contexts
import { usePackages } from "src/contexts/PackageContext";

// Utils
import toast from "react-hot-toast";
import composeErrorFormType from "src/utils/composeErrorFormType";
import { SUPPORTED_LANGUAGES, DEFAULT_META_DATA } from "src/utils/translationUtils";

// Services
import TagsService from "src/services/api/TagsService";

// Components
import AppMultiData from "src/components/ui/MultiData/AppMultiData";
import ServiceForm from "./ServiceForm";
import WarningForm from "./WarningForm";

const PackageForm = ({
  submit,
  formId,
  parentProps,
  userGroup,
  currentLanguage,
}) => {
  // Utilizziamo il context dei pacchetti
  const { getPackageByIndex, updatePackageTranslation } = usePackages();
  
  // Otteniamo il pacchetto corrente dal context (se stiamo modificando)
  const packageIndex = parentProps?.target?.data?.idx;
  const currentPackage = useMemo(() => {
    return packageIndex !== undefined ? getPackageByIndex(packageIndex) : null;
  }, [packageIndex, getPackageByIndex]);
  
  // Inizializziamo defaultValues in base al pacchetto corrente o vuoto
  const defaultValues = useMemo(() => {
    if (currentPackage) {
      return {
        ...currentPackage,
        // Assicuriamoci che tutte le lingue abbiano una struttura completa
        translations: SUPPORTED_LANGUAGES.reduce((acc, lang) => {
          acc[lang] = {
            package_title: currentPackage.translations?.[lang]?.package_title || '',
            package_description: currentPackage.translations?.[lang]?.package_description || '',
            included_services: currentPackage.translations?.[lang]?.included_services || [],
            warnings: currentPackage.translations?.[lang]?.warnings || [],
          };
          return acc;
        }, {}),
      };
    }
    
    // Default values per un nuovo pacchetto
    return {
      translations: SUPPORTED_LANGUAGES.reduce((acc, lang) => {
        acc[lang] = {
          package_title: '',
          package_description: '',
          included_services: [],
          warnings: [],
        };
        return acc;
      }, {}),
      name_tag: null,
      price: '',
      fee_amount: '',
      price_type_tag: null,
      quota_100: false,
    };
  }, [currentPackage]);

  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  // Teniamo uno stato locale solo per i campi che necessitano di aggiornamenti UI immediati
  const [localState, setLocalState] = useState({
    quota_100: defaultValues.quota_100,
    currentServices: defaultValues.translations[currentLanguage]?.included_services || [],
    currentWarnings: defaultValues.translations[currentLanguage]?.warnings || [],
  });

  const loadTags = (filter) =>
    new Promise((resolve) => {
      const tagsService = new TagsService();
      const okGetTags = (response) => {
        let responseData = [];
        if (Array.isArray(response.data) && response.data.length > 0) {
          responseData = response.data.map((currentItem) => ({
            value: currentItem._id,
            label: currentItem.tag,
          }));
        }
        resolve(responseData);
      };
      const koGetTags = () => resolve([]);
      const filters = {
        feed_id: "63750cc287c76e0016974c14", // Feed di nome "Pacchetto"
      };
      if (filter.length > 0) filters["??^tag"] = filter;
      tagsService.getList({
        paginate: 5,
        page: 1,
        filters,
        okCallback: (res) => okGetTags(res),
        koCallback: (err) => koGetTags(err),
      });
    });

  const loadPriceTypes = (filter) =>
    new Promise((resolve) => {
      const tagsService = new TagsService();
      const okGetTags = (response) => {
        let responseData = [];
        if (Array.isArray(response.data) && response.data.length > 0) {
          responseData = response.data.map((currentItem) => ({
            value: currentItem._id,
            label: currentItem.tag,
          }));
        }
        resolve(responseData);
      };
      const koGetTags = () => resolve([]);
      const filters = {
        feed_id: "63751cfe87c76e0016974c17", // Feed di nome "Tipo prezzo pacchetto"
      };
      if (filter.length > 0) filters["??^tag"] = filter;
      tagsService.getList({
        paginate: 5,
        page: 1,
        filters,
        okCallback: (res) => okGetTags(res),
        koCallback: (err) => koGetTags(err),
      });
    });

  const insertService = (data, formProps) => {
    // Aggiorniamo lo stato locale per l'UI
    const updatedServices = [...localState.currentServices, data.name];
    setLocalState(prev => ({
      ...prev,
      currentServices: updatedServices
    }));
    
    // Aggiorniamo il form
    setValue(`translations.${currentLanguage}.included_services`, updatedServices);
    
    // Se stiamo modificando un pacchetto, aggiorniamo anche il context
    if (packageIndex !== undefined) {
      updatePackageTranslation(packageIndex, currentLanguage, {
        included_services: updatedServices
      });
    }
    
    formProps.closeModal();
  };

  const editService = (data, formProps) => {
    if (typeof data.idx === "number" && data.idx >= 0) {
      // Creiamo una copia dei servizi attuali
      const updatedServices = [...localState.currentServices];
      // Aggiorniamo il servizio all'indice specificato
      updatedServices[data.idx] = data.name;
      
      // Aggiorniamo lo stato locale per l'UI
      setLocalState(prev => ({
        ...prev,
        currentServices: updatedServices
      }));
      
      // Aggiorniamo il form
      setValue(`translations.${currentLanguage}.included_services`, updatedServices);
      
      // Se stiamo modificando un pacchetto, aggiorniamo anche il context
      if (packageIndex !== undefined) {
        updatePackageTranslation(packageIndex, currentLanguage, {
          included_services: updatedServices
        });
      }
    }
    formProps.closeModal();
  };

  const deleteService = (data) => {
    if (typeof data.idx === "number" && data.idx >= 0) {
      // Creiamo una copia dei servizi attuali
      const updatedServices = [...localState.currentServices];
      // Rimuoviamo il servizio all'indice specificato
      updatedServices.splice(data.idx, 1);
      
      // Aggiorniamo lo stato locale per l'UI
      setLocalState(prev => ({
        ...prev,
        currentServices: updatedServices
      }));
      
      // Aggiorniamo il form
      setValue(`translations.${currentLanguage}.included_services`, updatedServices);
      
      // Se stiamo modificando un pacchetto, aggiorniamo anche il context
      if (packageIndex !== undefined) {
        if (currentLanguage === 'it') {
          // Se la lingua è italiano, eliminiamo il servizio in tutte le lingue
          SUPPORTED_LANGUAGES.forEach(lang => {
            // Otteniamo i servizi della lingua corrente
            const langServices = [...(currentPackage.translations?.[lang]?.included_services || [])];
            
            // Rimuoviamo il servizio all'indice specificato
            if (langServices.length > data.idx) {
              langServices.splice(data.idx, 1);
              
              // Aggiorniamo il context per questa lingua
              updatePackageTranslation(packageIndex, lang, {
                included_services: langServices
              });
            }
          });
        } else {
          // Se la lingua non è italiano, eliminiamo il servizio solo in quella lingua
          updatePackageTranslation(packageIndex, currentLanguage, {
            included_services: updatedServices
          });
        }
      }
    }
  };

  const insertWarning = (data, formProps) => {
    // Aggiorniamo lo stato locale per l'UI
    const updatedWarnings = [...localState.currentWarnings, data.name];
    setLocalState(prev => ({
      ...prev,
      currentWarnings: updatedWarnings
    }));
    
    // Aggiorniamo il form
    setValue(`translations.${currentLanguage}.warnings`, updatedWarnings);
    
    // Se stiamo modificando un pacchetto, aggiorniamo anche il context
    if (packageIndex !== undefined) {
      updatePackageTranslation(packageIndex, currentLanguage, {
        warnings: updatedWarnings
      });
    }
    
    formProps.closeModal();
  };

  const editWarning = (data, formProps) => {
    if (typeof data.idx === "number" && data.idx >= 0) {
      // Creiamo una copia delle avvertenze attuali
      const updatedWarnings = [...localState.currentWarnings];
      // Aggiorniamo l'avvertenza all'indice specificato
      updatedWarnings[data.idx] = data.name;
      
      // Aggiorniamo lo stato locale per l'UI
      setLocalState(prev => ({
        ...prev,
        currentWarnings: updatedWarnings
      }));
      
      // Aggiorniamo il form
      setValue(`translations.${currentLanguage}.warnings`, updatedWarnings);
      
      // Se stiamo modificando un pacchetto, aggiorniamo anche il context
      if (packageIndex !== undefined) {
        updatePackageTranslation(packageIndex, currentLanguage, {
          warnings: updatedWarnings
        });
      }
    }
    formProps.closeModal();
  };

  const deleteWarning = (data) => {
    if (typeof data.idx === "number" && data.idx >= 0) {
      // Creiamo una copia delle avvertenze attuali
      const updatedWarnings = [...localState.currentWarnings];
      // Rimuoviamo l'avvertenza all'indice specificato
      updatedWarnings.splice(data.idx, 1);
      
      // Aggiorniamo lo stato locale per l'UI
      setLocalState(prev => ({
        ...prev,
        currentWarnings: updatedWarnings
      }));
      
      // Aggiorniamo il form
      setValue(`translations.${currentLanguage}.warnings`, updatedWarnings);
      
      // Se stiamo modificando un pacchetto, aggiorniamo anche il context
      if (packageIndex !== undefined) {
        if (currentLanguage === 'it') {
          // Se la lingua è italiano, eliminiamo l'avvertenza in tutte le lingue
          SUPPORTED_LANGUAGES.forEach(lang => {
            // Otteniamo le avvertenze della lingua corrente
            const langWarnings = [...(currentPackage.translations?.[lang]?.warnings || [])];
            
            // Rimuoviamo l'avvertenza all'indice specificato
            if (langWarnings.length > data.idx) {
              langWarnings.splice(data.idx, 1);
              
              // Aggiorniamo il context per questa lingua
              updatePackageTranslation(packageIndex, lang, {
                warnings: langWarnings
              });
            }
          });
        } else {
          // Se la lingua non è italiano, eliminiamo l'avvertenza solo in quella lingua
          updatePackageTranslation(packageIndex, currentLanguage, {
            warnings: updatedWarnings
          });
        }
      }
    }
  };

  const toggleQuota100 = (e) => {
    const newValue = e.target.checked;
    setLocalState(prev => ({
      ...prev,
      quota_100: newValue
    }));
    setValue("quota_100", newValue);
  };

  const validateForm = (data) => {
    const { fee_amount, price, quota_100 } = data;
    const feeAmount = Number(fee_amount);
    const basePrice = Number(price);
    
    if (quota_100 && feeAmount === basePrice) {
      return {
        result: false,
        message: "Commissione e prezzo base non possono essere uguali quando la quota 100% è selezionata",
      };
    }
    
    if (feeAmount > basePrice) {
      return {
        result: false,
        message: "La commissione non può essere maggiore del prezzo base",
      };
    }
    
    return { result: true };
  };

  const onSubmit = (data) => {
    const validation = validateForm(data);
    if (!validation.result) {
      return toast.error(validation.message);
    }
    
    // Prendiamo le traduzioni più aggiornate dal Context, se disponibili
    let updatedTranslations = { ...data.translations };
    
    if (packageIndex !== undefined) {
      // Se stiamo modificando un pacchetto esistente, prendiamo tutte le traduzioni dal context
      const packageFromContext = getPackageByIndex(packageIndex);
      if (packageFromContext && packageFromContext.translations) {
        updatedTranslations = { ...packageFromContext.translations };
      }
    }
    
    // Assicuriamoci che i dati correnti della lingua attiva siano aggiornati
    updatedTranslations[currentLanguage] = {
      ...updatedTranslations[currentLanguage],
      package_title: data.translations[currentLanguage].package_title,
      package_description: data.translations[currentLanguage].package_description,
      included_services: localState.currentServices,
      warnings: localState.currentWarnings,
    };
    
    // Prepariamo i dati finali
    const formData = {
      ...data,
      translations: updatedTranslations
    };
    return submit(formData);
  };

  // Quando il form viene aperto o chiuso, o quando cambia la lingua
  useEffect(() => {
    if (typeof parentProps?.show !== "undefined") {
      if (parentProps.show === false) {
        // Reset del form quando viene chiuso
        reset(defaultValues);
        setLocalState({
          quota_100: defaultValues.quota_100,
          currentServices: defaultValues.translations[currentLanguage]?.included_services || [],
          currentWarnings: defaultValues.translations[currentLanguage]?.warnings || [],
        });
      } else if (currentPackage) {
        // Aggiorniamo il form quando viene aperto con un pacchetto esistente
        const formData = {
          ...currentPackage,
          translations: SUPPORTED_LANGUAGES.reduce((acc, lang) => {
            acc[lang] = {
              package_title: currentPackage.translations?.[lang]?.package_title || '',
              package_description: currentPackage.translations?.[lang]?.package_description || '',
              included_services: currentPackage.translations?.[lang]?.included_services || [],
              warnings: currentPackage.translations?.[lang]?.warnings || [],
            };
            return acc;
          }, {}),
        };
        
        reset(formData);
        setLocalState({
          quota_100: formData.quota_100,
          currentServices: formData.translations[currentLanguage]?.included_services || [],
          currentWarnings: formData.translations[currentLanguage]?.warnings || [],
        });
      }
    }
  }, [parentProps.show, currentLanguage, currentPackage, reset, defaultValues]);

  // Quando cambia la lingua, aggiorniamo i servizi e le avvertenze locali
  useEffect(() => {
    if (currentPackage) {
      setLocalState(prev => ({
        ...prev,
        currentServices: currentPackage.translations?.[currentLanguage]?.included_services || [],
        currentWarnings: currentPackage.translations?.[currentLanguage]?.warnings || [],
      }));
    }
  }, [currentLanguage, currentPackage]);

  return (
    <>
      <CForm id={formId} onSubmit={handleSubmit(onSubmit)}>
        <CCol md={12}>
          <Controller
            name={`translations.${currentLanguage}.package_title`}
            control={control}
            render={({ field }) => (
              <>
                <CFormTextarea
                  maxLength={165}
                  label={
                    <div>
                      <span className="fs-5">Meta Titolo pacchetto</span>
                      <br />
                      <small className="text-muted">Se non viene inserito un meta titolo, verrà utilizzato il valore predefinito mostrato nel placeholder</small>
                    </div>
                  }
                  placeholder={DEFAULT_META_DATA?.package_title?.[currentLanguage] || "Inserisci un meta titolo del pacchetto"}
                  id="package-title"
                  rows="1"
                  name={`translations.${currentLanguage}.package_title`}
                  value={field.value || ''}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    
                    // Se stiamo modificando un pacchetto, aggiorniamo anche il context
                    if (packageIndex !== undefined) {
                      updatePackageTranslation(packageIndex, currentLanguage, {
                        package_title: e.target.value
                      });
                    }
                  }}
                />
                <p className="text-align-end">
                  <small><strong>Max. 165 caratteri</strong></small>
                </p>
              </>
            )}
          />
        </CCol>
        <CCol md={12}>
          <Controller
            name={`translations.${currentLanguage}.package_description`}
            control={control}
            render={({ field }) => (
              <>
                <CFormTextarea
                  maxLength={1150}
                  label={
                    <div>
                      <span className="fs-5">Meta Descrizione del pacchetto</span>
                      <br />
                      <small className="text-muted">Se non viene inserita una meta descrizione, verrà utilizzato il valore predefinito mostrato nel placeholder</small>
                    </div>
                  }
                  placeholder={DEFAULT_META_DATA?.package_description?.[currentLanguage] || "Inserisci una meta descrizione del pacchetto"}
                  id="package-description"
                  rows="2"
                  name={`translations.${currentLanguage}.package_description`}
                  value={field.value || ''}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    
                    // Se stiamo modificando un pacchetto, aggiorniamo anche il context
                    if (packageIndex !== undefined) {
                      updatePackageTranslation(packageIndex, currentLanguage, {
                        package_description: e.target.value
                      });
                    }
                  }}
                />
                <p className="text-align-end">
                  <small><strong>Max. 1150 caratteri</strong></small>
                </p>
              </>
            )}
          />
        </CCol>
        <CRow>
          <CCol lg={5} md={6} sm={12}>
            <Controller
              name="name_tag"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <CFormLabel htmlFor="package-name_tag">Nome</CFormLabel>
                  <AsyncSelect
                    inputId="package-name_tag"
                    isClearable
                    defaultOptions
                    loadOptions={loadTags}
                    {...field}
                  />
                  {errors.name_tag ? (
                    <div className="invalid-feedback d-block">
                      {composeErrorFormType(errors.name_tag)}
                    </div>
                  ) : null}
                </>
              )}
            />
            <div className="mb-3" />
          </CCol>
          <CCol md="6" sm="12" lg="4">
            <CFormLabel htmlFor="tour-fee_amount">Commissione</CFormLabel>
            <Controller
              name="fee_amount"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CInputGroup>
                  <CFormInput
                    readOnly={userGroup !== "admin" && userGroup !== "superadmin"}
                    invalid={!!errors.price}
                    type="number"
                    min={0}
                    id="fee_amount"
                    className="text-align-end"
                    aria-describedby="package-price_append"
                    placeholder="Inserisci commissione"
                    {...field}
                  />
                  <CInputGroupText id="tour-price_append">€</CInputGroupText>
                </CInputGroup>
              )}
            />
            {errors.fee_amount ? (
              <div className="invalid-feedback d-block">
                {composeErrorFormType(errors.fee_amount)}
              </div>
            ) : null}
            <div className="mb-3" />
          </CCol>
          <CCol
            md={6}
            sm={12}
            lg={3}
            className="d-flex align-items-center justify-content-lg-center justify-content-start mt-lg-3 mb-lg-0 mb-3"
          >
            <Controller
              name="quota_100"
              control={control}
              render={({ field }) => (
                <CFormSwitch
                  disabled={userGroup !== "superadmin" && userGroup !== "admin"}
                  id="package-quota_100"
                  label="Quota 100%"
                  checked={localState.quota_100}
                  onChange={toggleQuota100}
                  size="lg"
                  style={{ minWidth: "40px", marginRight: "10px" }}
                />
              )}
            />
          </CCol>
        </CRow>
        <CRow>
          <CCol md={6} sm={12}>
            <CFormLabel htmlFor="tour-base_price">Prezzo base</CFormLabel>
            <Controller
              name="price"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <CInputGroup>
                  <CFormInput
                    invalid={!!errors.price}
                    type="number"
                    id="price"
                    className="text-align-end"
                    aria-describedby="package-price_append"
                    placeholder="Inserisci prezzo"
                    {...field}
                  />
                  <CInputGroupText id="tour-price_append">€</CInputGroupText>
                </CInputGroup>
              )}
            />
            {errors.price ? (
              <div className="invalid-feedback d-block">
                {composeErrorFormType(errors.price)}
              </div>
            ) : null}
            <div className="mb-3" />
          </CCol>
          <CCol md={6} sm={12}>
            <Controller
              name="price_type_tag"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <>
                  <CFormLabel htmlFor="package-price_type_tag">
                    Tipologia prezzo
                  </CFormLabel>
                  <AsyncSelect
                    inputId="package-price_type_tag"
                    isClearable
                    defaultOptions
                    loadOptions={loadPriceTypes}
                    {...field}
                  />
                  {errors.price_type_tag ? (
                    <div className="invalid-feedback d-block">
                      {composeErrorFormType(errors.price_type_tag)}
                    </div>
                  ) : null}
                </>
              )}
            />
            <div className="mb-3" />
          </CCol>
        </CRow>
        <input 
          type="hidden" 
          name={`translations.${currentLanguage}.included_services`}
          value={JSON.stringify(localState.currentServices)}
        />
        <input 
          type="hidden" 
          name={`translations.${currentLanguage}.warnings`}
          value={JSON.stringify(localState.currentWarnings)}
        />
      </CForm>
      <AppMultiData
        buttonAddString="Aggiungi Servizio"
        className="mb-4"
        title="Servizi inclusi"
        item="Servizio"
        formId="servizio-pacchetto"
        createFormComponent={(CreateFormProps) =>
          ServiceForm({
            formId: "create_servizio-pacchetto",
            submit: (data) => insertService(data, CreateFormProps),
            parentProps: {
              show: CreateFormProps.show,
            },
          })
        }
        editFormComponent={(EditFormProps) =>
          ServiceForm({
            formId: "edit_servizio-pacchetto",
            submit: (data) => editService(data, EditFormProps),
            parentProps: {
              show: EditFormProps.show,
              target: EditFormProps.target,
            },
          })
        }
        deleteFunction={(deleteProps) => deleteService(deleteProps)}
        data={localState.currentServices}
        singleField
        modalAlign="center"
      />
      <AppMultiData
        buttonAddString="Aggiungi Avvertenza"
        className="mb-4"
        title="Avvertenze pacchetto"
        item="Avvertenza"
        formId="avvertenza-pacchetto"
        createFormComponent={(CreateFormProps) =>
          WarningForm({
            formId: "create_avvertenza-pacchetto",
            submit: (data) => insertWarning(data, CreateFormProps),
            parentProps: {
              show: CreateFormProps.show,
            },
          })
        }
        editFormComponent={(EditFormProps) =>
          WarningForm({
            formId: "edit_avvertenza-pacchetto",
            submit: (data) => editWarning(data, EditFormProps),
            parentProps: {
              show: EditFormProps.show,
              target: EditFormProps.target,
            },
          })
        }
        deleteFunction={(deleteProps) => deleteWarning(deleteProps)}
        data={localState.currentWarnings}
        singleField
        modalAlign="center"
      />
    </>
  );
};

PackageForm.propTypes = {
  formId: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  parentProps: PropTypes.shape({
    show: PropTypes.bool,
    target: PropTypes.shape({
      data: PropTypes.object,
    }),
  }),
  userGroup: PropTypes.string,
  currentLanguage: PropTypes.string,
};

PackageForm.defaultProps = {
  parentProps: {},
  userGroup: null,
  currentLanguage: "it",
};

export default PackageForm;

