/* eslint-disable */
import { useState, useCallback, useRef } from 'react';
import toast from 'react-hot-toast';
import translationService from 'src/services/api/TranslationService';
import { prepareTranslationInstructions } from 'src/utils/translationUtils';

/**
 * Custom hook per gestire le traduzioni automatiche in batch
 * @param {Object} options - Opzioni di configurazione
 * @param {string} options.type - Tipo di contenuto da tradurre (collection name)
 * @param {Object} options.originalModel - Il modello originale con i dati iniziali
 * @param {Function} options.onTranslationComplete - Callback chiamato al completamento
 * @param {number} options.batchSize - Dimensione del batch (default: 4)
 */
const useTranslationBatch = ({ 
  type, 
  originalModel, 
  onTranslationComplete,
  batchSize = 4
}) => {
  // Stato per la modale di traduzione
  const [translationModal, setTranslationModal] = useState({
    show: false,
    fieldsToTranslate: null,
  });
  
  // Stato per le lingue selezionate
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  
  // Stato per il progresso della traduzione
  const [translationProgress, setTranslationProgress] = useState({
    isTranslating: false,
    progress: 0,
    currentBatch: 0,
    totalBatches: 0,
    currentBatchLanguages: [],
    translationStatus: {},
  });

  // Riferimento per memorizzare le traduzioni già completate con successo
  // in questa sessione (prima del salvataggio)
  const currentSessionTranslations = useRef({});

  // Funzione per aprire la modale di traduzione
  const handleTranslate = useCallback((fieldsToTranslateInput) => {
    // Estraiamo il callback onTranslationComplete se presente
    let fieldsToTranslate = fieldsToTranslateInput;
    let customCallback = null;
    let preserveExistingPackageTranslations = null;
    
    if (typeof fieldsToTranslateInput === 'object' && fieldsToTranslateInput !== null) {
      // Estraiamo il callback e altri campi speciali
      const { 
        onTranslationComplete: extractedCallback, 
        _preserveExistingPackageTranslations,
        ...fields 
      } = fieldsToTranslateInput;
      
      if (extractedCallback) {
        customCallback = extractedCallback;
      }
      
      if (_preserveExistingPackageTranslations) {
        preserveExistingPackageTranslations = _preserveExistingPackageTranslations;
      }
      
      fieldsToTranslate = fields;
    }
    
    setTranslationModal({
      show: true,
      fieldsToTranslate,
      onTranslationComplete: customCallback || onTranslationComplete,
      preserveExistingPackageTranslations
    });
    
    // Reset degli stati
    setSelectedLanguages([]);
    setTranslationProgress({
      isTranslating: false,
      progress: 0,
      currentBatch: 0,
      totalBatches: 0,
      currentBatchLanguages: [],
      translationStatus: {},
    });
  }, [onTranslationComplete]);

  // Funzione per chiudere la modale di traduzione
  const closeTranslationModal = useCallback(() => {
    setTranslationModal({ 
      show: false, 
      fieldsToTranslate: null,
      onTranslationComplete: onTranslationComplete, // Reset al callback originale
      preserveExistingPackageTranslations: null
    });
    setSelectedLanguages([]);
    setTranslationProgress({
      isTranslating: false,
      progress: 0,
      currentBatch: 0,
      totalBatches: 0,
      currentBatchLanguages: [],
      translationStatus: {},
    });
  }, [onTranslationComplete]);

  // Funzione per eseguire la traduzione
  const executeTranslation = useCallback(() => {
    if (!translationModal.fieldsToTranslate || selectedLanguages.length === 0) {
      toast.error('Seleziona almeno una lingua per la traduzione');
      return;
    }

    // Prepariamo i dati per la traduzione
    const originalValues = originalModel?.translations?.it || {};
    const currentValues = translationModal.fieldsToTranslate;

    const instructions = prepareTranslationInstructions(
      originalValues,
      currentValues,
      originalModel?.translations || {},
      Object.keys(translationModal.fieldsToTranslate),
    );

    // Includiamo le traduzioni già completate con successo in questa sessione
    // così come le traduzioni dal modello originale
    const existingTranslations = {
      ...originalModel?.translations || {},
      ...currentSessionTranslations.current,
    };

    // Se esistono traduzioni di pacchetti da preservare, le includiamo per ogni lingua
    // Questo è importante per non perdere le traduzioni precedenti di un pacchetto
    if (translationModal.preserveExistingPackageTranslations) {
      Object.keys(translationModal.preserveExistingPackageTranslations).forEach(lang => {
        if (existingTranslations[lang]) {
          // Preserva i campi del pacchetto nella lingua
          existingTranslations[lang] = {
            ...existingTranslations[lang],
            package_title: translationModal.preserveExistingPackageTranslations[lang].package_title || "",
            package_description: translationModal.preserveExistingPackageTranslations[lang].package_description || "",
            included_services: translationModal.preserveExistingPackageTranslations[lang].included_services || [],
            warnings: translationModal.preserveExistingPackageTranslations[lang].warnings || []
          };
        } else {
          // Crea una nuova struttura per la lingua se non esiste
          existingTranslations[lang] = {
            ...translationModal.preserveExistingPackageTranslations[lang]
          };
        }
      });
    }

    const body = {
      translations: {
        ...existingTranslations,
        it: {
          ...originalModel?.translations?.it,
          ...translationModal.fieldsToTranslate,
        },
      },
      instructions,
    };

    // Usiamo il callback specificato o quello di default
    const completeCallback = translationModal.onTranslationComplete || onTranslationComplete;

    // Completamento traduzione con aggiornamento delle traduzioni in memoria
    const handleSuccessfulTranslation = (translations) => {
      // Assicuriamoci di preservare i campi del pacchetto nelle traduzioni aggiornate
      const updatedTranslations = { ...translations };
      
      if (translationModal.preserveExistingPackageTranslations) {
        // Per ogni lingua tradotta, assicuriamoci che i campi del pacchetto siano mantenuti
        Object.keys(updatedTranslations).forEach(lang => {
          // Assicuriamoci che i campi del pacchetto siano sempre inclusi nelle traduzioni
          if (translationModal.fieldsToTranslate.package_title !== undefined || 
              translationModal.preserveExistingPackageTranslations[lang]?.package_title !== undefined) {
            updatedTranslations[lang] = {
              ...updatedTranslations[lang],
              package_title: updatedTranslations[lang]?.package_title || translationModal.preserveExistingPackageTranslations[lang]?.package_title || "",
              package_description: updatedTranslations[lang]?.package_description || translationModal.preserveExistingPackageTranslations[lang]?.package_description || "",
              included_services: updatedTranslations[lang]?.included_services || translationModal.preserveExistingPackageTranslations[lang]?.included_services || [],
              warnings: updatedTranslations[lang]?.warnings || translationModal.preserveExistingPackageTranslations[lang]?.warnings || []
            };
          }
        });
      }

      // Salviamo le traduzioni completate nella sessione corrente
      currentSessionTranslations.current = {
        ...currentSessionTranslations.current,
        ...updatedTranslations,
      };

      // Chiamiamo il callback di completamento
      completeCallback(updatedTranslations);
    };

    // Se ci sono 4 o meno lingue selezionate, usiamo il metodo tradizionale
    if (selectedLanguages.length <= batchSize) {
      setTranslationProgress(prev => ({
        ...prev,
        isTranslating: true,
        progress: 0,
        currentBatchLanguages: selectedLanguages,
        translationStatus: selectedLanguages.reduce((acc, lang) => {
          acc[lang] = 'translating';
          return acc;
        }, {}),
      }));

      // Aggiungiamo le lingue selezionate al body
      body.languages = selectedLanguages;

      translationService.translateFields(
        type,
        body,
        (response) => {
          // Aggiorniamo lo stato di traduzione
          setTranslationProgress(prev => ({
            ...prev,
            isTranslating: false,
            progress: 100,
            translationStatus: selectedLanguages.reduce((acc, lang) => {
              acc[lang] = 'success';
              return acc;
            }, {}),
          }));

          // Salviamo le traduzioni e chiamiamo il callback
          handleSuccessfulTranslation(response.data.translations);
          
          // Chiudiamo la modale dopo un breve ritardo
          setTimeout(() => {
            closeTranslationModal();
            toast.success('Traduzioni completate con successo');
          }, 1000);
        },
        (error) => {
          console.error('Translation error:', error);
          
          // Aggiorniamo lo stato di traduzione
          setTranslationProgress(prev => ({
            ...prev,
            isTranslating: false,
            translationStatus: selectedLanguages.reduce((acc, lang) => {
              acc[lang] = 'error';
              return acc;
            }, {}),
          }));
          
          toast.error('Errore durante la traduzione');
        },
      );
    } else {
      // Se ci sono più di 4 lingue, usiamo il metodo a batch
      setTranslationProgress(prev => ({
        ...prev,
        isTranslating: true,
        progress: 0,
        translationStatus: selectedLanguages.reduce((acc, lang) => {
          acc[lang] = 'pending';
          return acc;
        }, {}),
      }));

      // Usiamo il metodo di traduzione in batch
      translationService.translateInBatches(
        type,
        body,
        selectedLanguages,
        batchSize,
        (progressData) => {
          // Aggiorniamo lo stato di progresso
          setTranslationProgress(prev => ({
            ...prev,
            isTranslating: true,
            progress: progressData.progress,
            currentBatch: progressData.currentBatch,
            totalBatches: progressData.totalBatches,
            currentBatchLanguages: progressData.currentBatchLanguages,
            translationStatus: progressData.translationStatus,
          }));
        },
        (batchIndex, translations) => {
          // Questo callback viene chiamato quando un batch è completato
          // Aggiorniamo le traduzioni in memoria per questo batch, preservando i campi del pacchetto
          const updatedBatchTranslations = { ...translations };
          
          if (translationModal.preserveExistingPackageTranslations) {
            // Per ogni lingua tradotta nel batch, assicuriamoci che i campi del pacchetto siano mantenuti
            Object.keys(updatedBatchTranslations).forEach(lang => {
              if (translationModal.fieldsToTranslate.package_title !== undefined || 
                  translationModal.preserveExistingPackageTranslations[lang]?.package_title !== undefined) {
                updatedBatchTranslations[lang] = {
                  ...updatedBatchTranslations[lang],
                  package_title: updatedBatchTranslations[lang]?.package_title || translationModal.preserveExistingPackageTranslations[lang]?.package_title || "",
                  package_description: updatedBatchTranslations[lang]?.package_description || translationModal.preserveExistingPackageTranslations[lang]?.package_description || "",
                  included_services: updatedBatchTranslations[lang]?.included_services || translationModal.preserveExistingPackageTranslations[lang]?.included_services || [],
                  warnings: updatedBatchTranslations[lang]?.warnings || translationModal.preserveExistingPackageTranslations[lang]?.warnings || []
                };
              }
            });
          }
          
          currentSessionTranslations.current = {
            ...currentSessionTranslations.current,
            ...updatedBatchTranslations,
          };
          console.log(`Batch ${batchIndex + 1} completato`);
        },
        (translations) => {
          // Questo callback viene chiamato quando tutti i batch sono completati
          // Salviamo le traduzioni e chiamiamo il callback
          handleSuccessfulTranslation(translations);
          
          // Chiudiamo la modale dopo un breve ritardo
          setTimeout(() => {
            closeTranslationModal();
            toast.success('Traduzioni completate con successo');
          }, 1000);
        },
        (error, batchIndex) => {
          // Questo callback viene chiamato quando un batch ha un errore
          console.error(`Errore nel batch ${batchIndex + 1}:`, error);
          toast.error(`Errore nel batch ${batchIndex + 1}`);
        },
      );
    }
  }, [type, originalModel, translationModal, selectedLanguages, batchSize, onTranslationComplete, closeTranslationModal]);

  // Funzione per resettare le traduzioni in memoria dopo il salvataggio
  const resetSessionTranslations = useCallback(() => {
    currentSessionTranslations.current = {};
  }, []);

  return {
    translationModal: {
      ...translationModal,
      ...translationProgress,
    },
    selectedLanguages,
    setSelectedLanguages,
    handleTranslate,
    executeTranslation,
    closeTranslationModal,
    resetSessionTranslations, // Esponiamo questa funzione in modo che possa essere chiamata dopo il salvataggio
  };
};

export default useTranslationBatch; 