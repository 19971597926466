/* eslint-disable default-param-last */
import ApiProxyService from './apiProxyService';
import utils from './utils/utils';

export default class SpecialVouchersService extends ApiProxyService {
  BASE_PATH = '/special_vouchers';

  ASSOCIATED_TOURS_PATH = '/products';

  getList({
    paginate,
    page,
    order = 'name',
    sort = 'ASC',
    filters,
    okCallback,
    koCallback,
  }) {
    let queryParams = {
      paginate, page, order_by: order, sort_by: sort, lookup: '[order_id]',
    };
    let searchFilters = {};
    if (filters) {
      searchFilters = { ...filters };
      if (filters.search && filters.search.length > 0) {
        searchFilters['??_^name'] = filters.search;
        delete searchFilters.search;
      }
      queryParams = {
        ...queryParams, ...searchFilters,
      };
    }
    const path = `${this.BASE_PATH}`;
    super.getList(path, queryParams, okCallback, koCallback);
  }

  getItem(itemId, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${itemId}`;
    const pathWithQueryParams = utils.buildPathWithQueryParams(path, { lookup: '[order_id]' });
    super.getItem(pathWithQueryParams, okCallback, koCallback);
  }

  addItem(body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}`;
    const parsedBody = { };
    parsedBody.name = body?.voucher_name;
    parsedBody.description = body?.description;
    parsedBody.value = body?.maxPrice;
    parsedBody.min_price = body?.minPrice;
    parsedBody.max_price = body?.maxPrice;
    parsedBody.quantity = body?.quantity;
    parsedBody.tours = body?.new_tour_select?.map((currentTour) => {
      const parsedTour = { };
      parsedTour.name = currentTour?.name;
      parsedTour.package_name = currentTour?.package_name;
      parsedTour.product_id = currentTour?.product_id;
      parsedTour.purchase_option_id = currentTour?.purchase_option_id;
      return parsedTour;
    });
    super.addItem(path, parsedBody, okCallback, koCallback);
  }

  updateItem(id, body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${id}`;
    super.updateItem(path, body, okCallback, koCallback);
  }

  deleteItem(deleteInfo, okCallback, koCallback) {
    const deletePromisesArray = [];
    if (typeof deleteInfo === 'string' || typeof deleteInfo === 'number') {
      deletePromisesArray.push(
        new Promise((resolve, reject) => {
          super.deleteItem(`${this.BASE_PATH}/${deleteInfo}`, (res) => resolve(res), (err) => reject(err));
        }),
      );
    } else if (Array.isArray(deleteInfo)) {
      deleteInfo.forEach((currentItem) => {
        deletePromisesArray.push(
          new Promise((resolve, reject) => {
            super.deleteItem(`${this.BASE_PATH}/${currentItem}`, (res) => resolve(res), (err) => reject(err));
          }),
        );
      });
    }
    Promise.allSettled(deletePromisesArray)
      .then((results) => {
        const rejectedPromises = results.filter((currentResult) => currentResult.status === 'rejected');
        if (rejectedPromises.length <= 0) okCallback();
        else koCallback([...rejectedPromises]);
      });
  }

  addNewCodes(id, body, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${id}/generate-codes`;
    super.addItem(path, body, okCallback, koCallback);
  }

  getVoucherTours(body, nameFilter, okCallback, koCallback) {
    const path = `${this.ASSOCIATED_TOURS_PATH}/filter${nameFilter ? `???_^name=${nameFilter}` : ''}`;
    super.addItem(path, body, okCallback, koCallback);
  }

  getCsv(specialVoucherId, okCallback, koCallback) {
    const path = `${this.BASE_PATH}/${specialVoucherId}/export`;
    super.getItem(path, okCallback, koCallback);
  }
}
