export const SUPPORTED_LANGUAGES = ['it', 'en', 'da', 'de', 'es', 'et', 'fr', 'is', 'lt', 'nl', 'no', 'pl', 'sv'];

export const LANGUAGE_OPTIONS = [
  { value: 'it', label: 'Italiano' },
  { value: 'en', label: 'Inglese' },
  { value: 'da', label: 'Danese' },
  { value: 'de', label: 'Tedesco' },
  { value: 'es', label: 'Spagnolo' },
  { value: 'et', label: 'Estone' },
  { value: 'fr', label: 'Francese' },
  { value: 'is', label: 'Islandese' },
  { value: 'lt', label: 'Lituano' },
  { value: 'nl', label: 'Olandese' },
  { value: 'no', label: 'Norvegese' },
  { value: 'pl', label: 'Polacco' },
  { value: 'sv', label: 'Svedese' },
];

/**
 * Controlla se un campo necessita di traduzione
 * @param {*} originalValue - Valore originale in italiano
 * @param {*} currentValue - Valore corrente in italiano
 * @param {Object} translations - Oggetto contenente le traduzioni nelle varie lingue
 * @param {string} field - Nome del campo da controllare
 * @returns {boolean} - true se il campo necessita di traduzione
 */

export const needsTranslation = (
  originalValue,
  currentValue,
  translations,
  field,
) => {
  // Funzione per verificare se un valore è vuoto (stringa vuota o array vuoto)
  const isEmpty = (value) => {
    if (Array.isArray(value)) {
      return value.length === 0;
    }
    return !value || (typeof value === 'string' && value.trim() === '');
  };

  // Se sia l'originale che il corrente sono vuoti, non c'è bisogno di tradurre
  if (isEmpty(originalValue) && isEmpty(currentValue)) {
    return false;
  }

  const hasChanged = Array.isArray(originalValue)
    ? JSON.stringify(originalValue) !== JSON.stringify(currentValue)
    : originalValue !== currentValue;

  const otherLanguagesEmpty = SUPPORTED_LANGUAGES.filter(
    (lang) => lang !== 'it',
  ).some((lang) => {
    const langValue = translations?.[lang]?.[field];
    // Se il valore italiano è vuoto, non consideriamo
    // le altre lingue vuote come mancanti di traduzione
    if (isEmpty(currentValue)) {
      return false;
    }
    return isEmpty(langValue);
  });

  return hasChanged || otherLanguagesEmpty;
};

/**
 * Prepara le istruzioni per la traduzione
 * @param {Object} originalValues - Valori originali in italiano
 * @param {Object} currentValues - Valori correnti in italiano
 * @param {Object} translations - Oggetto contenente le traduzioni
 * @param {Array} fields - Array di campi da controllare
 * @returns {Object} - Oggetto contenente le istruzioni per la traduzione
 */
export const prepareTranslationInstructions = (
  originalValues,
  currentValues,
  translations,
  fields,
) => {
  const instructions = {};

  fields.forEach((field) => {
    instructions[field] = {
      value: currentValues[field],
      translate: needsTranslation(
        originalValues[field],
        currentValues[field],
        translations,
        field,
      ),
    };
  });
  return instructions;
};

/**
 * Inizializza la struttura delle traduzioni con valori di default
 * @param {Object} italianValues - Valori in italiano (opzionale)
 * @returns {Object} - Oggetto con la struttura delle traduzioni inizializzata
 */
export const initializeTranslations = (italianValues = {}) => {
  const translations = {};

  // Inizializziamo prima l'italiano con i valori forniti
  translations.it = {
    package_title: italianValues.package_title || '',
    package_description: italianValues.package_description || '',
    included_services: italianValues.included_services || [],
    warnings: italianValues.warnings || [],
  };

  // Inizializziamo le altre lingue con valori vuoti
  SUPPORTED_LANGUAGES
    .filter((lang) => lang !== 'it')
    .forEach((lang) => {
      translations[lang] = {
        package_title: '',
        package_description: '',
        included_services: [],
        warnings: [],
      };
    });

  return translations;
};

// Placeholder dei metadati dal front-end nel caso non vengano immessi a back-office
export const DEFAULT_META_DATA = {
  meta_title: {
    it: 'Tour di Degustazione {{TOUR_NAME}}, a {{ZONE_NAME}} - Vinbacco.com',
    en: '{{TOUR_NAME}} Wine Tour, in {{ZONE_NAME}} | Vinbacco.com',
    fr: 'Tour de dégustation {{TOUR_NAME}}, à {{ZONE_NAME}} | Vinbacco.com',
    de: 'Weinverkostung Tour {{TOUR_NAME}}, in {{ZONE_NAME}} | Vinbacco.com',
    es: 'Degustación de vinos {{TOUR_NAME}}, en {{ZONE_NAME}} | Vinbacco.com',
    nl: 'Proeverij Tour {{TOUR_NAME}}, in {{ZONE_NAME}} - Vinbacco.com',
    pl: 'Wycieczka Degustacyjna {{TOUR_NAME}}, w {{ZONE_NAME}} - Vinbacco.com',
    da: 'Smagningstur {{TOUR_NAME}}, i {{ZONE_NAME}} - Vinbacco.com',
    sv: 'Provningstur {{TOUR_NAME}}, i {{ZONE_NAME}} - Vinbacco.com',
    lt: 'Degustacijos turas {{TOUR_NAME}}, {{ZONE_NAME}} - Vinbacco.com',
    et: 'Degusteerimisreis {{TOUR_NAME}}, piirkonnas {{ZONE_NAME}} - Vinbacco.com',
    no: 'Smakingstur {{TOUR_NAME}}, i {{ZONE_NAME}} - Vinbacco.com',
    is: 'Smökkunarferð {{TOUR_NAME}}, í {{ZONE_NAME}} - Vinbacco.com',
  },
  meta_description: {
    it: "Prenota subito il Tour {{TOUR_NAME}}. Vivi un'esperienza indimenticabile tra vini d'eccellenza, paesaggi suggestivi e cantine che uniscono tradizione e innovazione.",
    en: 'Book the {{TOUR_NAME}} Tour now. Enjoy an unforgettable experience among fine wines, picturesque landscapes and wineries that blend tradition and innovation.',
    fr: "Réservez dès ici l'excursion {{TOUR_NAME}}. Vivez une expérience inoubliable entre des vins excellents, des paysages évocateurs et des caves qui allient tradition et innovation.",
    de: 'Buchen Sie jetzt die {{TOUR_NAME}} Tour. Erleben Sie eine unvergessliche Erfahrung inmitten von Weinen, Landschaften und Weingütern, die Tradition und Innovation vereinen.',
    es: 'Reserve ahora el Tour {{TOUR_NAME}}. Viva una experiencia inolvidable entre excelentes vinos, paisajes evocadores y bodegas que combinan tradición e innovación.',
    nl: 'Boek nu de Tour {{TOUR_NAME}}. Beleef een onvergetelijke ervaring tussen uitstekende wijnen, adembenemende landschappen en wijnhuizen die traditie en innovatie combineren.',
    pl: 'Zarezerwuj teraz wycieczkę {{TOUR_NAME}}. Przeżyj niezapomniane doświadczenie wśród wybitnych win, malowniczych krajobrazów i winnic łączących tradycję z innowacją.',
    da: 'Book nu {{TOUR_NAME}} Turen. Oplev en uforglemmelig oplevelse blandt fremragende vine, betagende landskaber og vingårde, der forener tradition og innovation.',
    sv: 'Boka nu {{TOUR_NAME}} Turen. Upplev en oförglömlig upplevelse bland utmärkta viner, hänförande landskap och vingårdar som förenar tradition och innovation.',
    lt: 'Rezervuokite dabar {{TOUR_NAME}} Turą. Patirkite nepamirštamą patirtį tarp puikių vynų, įspūdingų kraštovaizdžių ir vyninių, kurios sujungia tradiciją ir inovacijas.',
    et: 'Broneeri kohe {{TOUR_NAME}} ekskursioon. Kogege unustamatut elamust suurepäraste veinide, maaliliste maastike ja veinimõisate keskel, mis ühendavad traditsioone ja innovatsiooni.',
    no: 'Bestill nå {{TOUR_NAME}} Turen. Opplev en uforglemmelig opplevelse blant utmerkede viner, betagende landskap og vingårder som forener tradisjon og innovasjon.',
    is: 'Bókaðu núna {{TOUR_NAME}} ferðina. Upplifðu ógleymanlega stund meðal framúrskarandi vína, stórbrotins landslags og víngerða sem sameina hefð og nýsköpun.',
  },
  package_title: {
    it: 'Pacchetto {{PACKAGE_NAME}} - Degustazione vini e visita cantina {{ZONE_NAME}} | Vinbacco.com',
    en: 'Package {{PACKAGE_NAME}} - Wine tasting and Visit {{ZONE_NAME}} | Vinbacco.com',
    fr: 'Formule de {{PACKAGE_NAME}} - Dégustation de vin et visite de la cave {{ZONE_NAME}} | Vinbacco.com',
    de: 'Paket {{PACKAGE_NAME}} - Weinprobe und Besichtigung der Weinkellerei {{ZONE_NAME}} | Vinbacco.com',
    es: 'Paquete {{PACKAGE_NAME}} - Cata de vinos y visita a la Cantina {{ZONE_NAME}} | Vinbacco.com',
    nl: 'Pakket {{PACKAGE_NAME}} - Wijnproeverij en wijnhuisbezoek {{ZONE_NAME}} | Vinbacco.com',
    pl: 'Pakiet {{PACKAGE_NAME}} - Degustacja win i zwiedzanie winnicy {{ZONE_NAME}} | Vinbacco.com',
    da: 'Pakke {{PACKAGE_NAME}} - Vinsmagning og vingårdsbesøg {{ZONE_NAME}} | Vinbacco.com',
    sv: 'Paket {{PACKAGE_NAME}} - Vinprovning och vingårdsbesök {{ZONE_NAME}} | Vinbacco.com',
    lt: 'Paketas {{PACKAGE_NAME}} - Vyno degustacija ir vyninės lankymas {{ZONE_NAME}} | Vinbacco.com',
    et: 'Pakett {{PACKAGE_NAME}} - Veinidegustatsioon ja külastus {{ZONE_NAME}} | Vinbacco.com',
    no: 'Pakke {{PACKAGE_NAME}} - Vinsmaking og vingårdsbesøk {{ZONE_NAME}} | Vinbacco.com',
    is: 'Pakki {{PACKAGE_NAME}} - Vínsmaking og víngerðarheimsókn {{ZONE_NAME}} | Vinbacco.com',
  },
  package_description: {
    it: "Scopri le offerte del Tour {{TOUR_NAME}} e scegli il tuo pacchetto di degustazione. Sarà un'esperienza indimenticabile tra vini e paesaggi suggestivi.",
    en: 'Discover the {{TOUR_NAME}} Tour offerings and choose the tasting package you prefer. Enjoy an unforgettable experience among fine wines and picturesque landscapes.',
    fr: 'Découvrez les offres de visites de {{TOUR_NAME}} et choisissez votre formule de dégustation. Ce sera une expérience inoubliable entre vins et paysages évocateurs.',
    de: 'Entdecken Sie die Angebote der {{TOUR_NAME}}-Tour und wählen Sie Ihr Verkostungspaket. Es wird ein unvergessliches Erlebnis inmitten von Weinen und stimmungsvollen Landschaften sein.',
    es: 'Descubra las ofertas de tours de {{TOUR_NAME}} y elija su paquete de degustación. Será una experiencia inolvidable entre vinos y paisajes evocadores.',
    nl: 'Ontdek de aanbiedingen van Tour {{TOUR_NAME}} en kies je proeverijpakket. Het wordt een onvergetelijke ervaring tussen wijnen en adembenemende landschappen.',
    pl: 'Odkryj oferty wycieczki {{TOUR_NAME}} i wybierz swój pakiet degustacyjny. To będzie niezapomniane doświadczenie wśród win i malowniczych krajobrazów.',
    da: 'Opdag tilbuddene på {{TOUR_NAME}} Turen og vælg din smagningspakke. Det bliver en uforglemmelig oplevelse blandt vine og betagende landskaber.',
    sv: 'Upptäck erbjudandena på {{TOUR_NAME}} Turen och välj ditt provningspaket. Det blir en oförglömlig upplevelse bland viner och hänförande landskap.',
    lt: 'Atraskite {{TOUR_NAME}} Turo pasiūlymus ir pasirinkite savo degustacijos paketą. Tai bus nepamirštama patirtis tarp vynų ir įspūdingų kraštovaizdžių.',
    et: 'Avastage {{TOUR_NAME}} Tuuri pakkumised ja valige oma degusteerimispakett. See saab olema unustamatu elamus veinide ja hingematva maastiku keskel.',
    no: 'Oppdag tilbudene på {{TOUR_NAME}} Turen og velg din smakingspakke. Det blir en uforglemmelig opplevelse blant viner og betagende landskap.',
    is: 'Uppgötvaðu tilboðin á {{TOUR_NAME}} Ferðinni og veldu þinn smökkuarpakka. Það verður ógleymanleg upplifun meðal vína og stórbrotins landslags.',
  },
};
