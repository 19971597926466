/* eslint-disable */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import {
  CCol,
  CForm,
  CFormInput,
  CRow,
  CButton,
  CInputGroup,
  CFormLabel,
  CFormCheck,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CAlert,
  CAlertHeading,
  CFormSelect,
} from '@coreui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import {
  cilFile, cilPencil, cilPlus, cilTrash, cilCloudDownload,
} from '@coreui/icons';
import { useSelector } from 'react-redux';
import AppTable from 'src/components/ui/List/AppTable';
import Pagination from 'src/components/ui/List/Pagination';
import AppLoadingSpinner from '../AppLoadingSpinner';
import InvoicingExportModal from './InvoicingExportModal';

// Utils
import validateDateRange from 'src/utils/dateValidation';
import dataToQueryParams from 'src/utils/dataToQueryParams';
import getExportFields from './utils/exportDataChoice';

function AppList({
  scrollable,
  sectionId,
  SectionServiceClass,
  sectionPath,
  sectionTitle,
  creationTitle,
  mapListFn,
  buildColumnsFn,
  buildRowsFn,
  creationBodyFn,
  clearCreationModel,
  evalCreation,
  formatCreationData,
  initialSortField,
  CustomFilter,
  isExportable,
  isInvoicingExportable,
  initialSortOrder,
  filterOptions,
  invoicingExportModalProps,
  isAdvancedExportable,
  advancedExportModalProps,
}) {
  const userData = useSelector((state) => state.user.value);
  const inputRef = useRef(null);
  const permissions = {
    group: userData.user_group,
    create: userData.permissions.find((current) => current === `${sectionId}_create`) !== undefined,
    edit: userData.permissions.find((current) => current === `${sectionId}_edit`) !== undefined,
    delete: userData.permissions.find((current) => current === `${sectionId}_delete`) !== undefined,
  };

  const sectionService = new SectionServiceClass();
  const location = useLocation();
  const [state, setState] = useState({ selectedItems: [] });
  const [creationAction, setCreationAction] = useState({ error: null, executing: false });
  const [deleteAction, setDeleteAction] = useState({
    error: null, executing: false, success: null,
  });
  const [exportAction, setExportAction] = useState({
    error: null, executing: false, success: null,
  });
  const [selectedExportFields, setSelectedExportFields] = useState([]);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      listFilter: '',
      customFilter: {},
    },
  });
  const initialTableData = {
    paginate: 30,
    page: null,
    total: 0,
    order: null,
    sort: null,
    search: null,
    data: null,
    searchParam: null,
  };
  const [tableData, setTableData] = useState(initialTableData);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedOptionFilter, setSelectedOptionFilter] = useState(null);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showInvoicingExportModal, setShowInvoicingExportModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showAdvancedExportModal, setShowAdvancedExportModal] = useState(false);
  const navigate = useNavigate();
  const toggleSelectAllRows = (event) => {
    const newState = { ...state };
    const isChecked = event.target.checked;
    if (isChecked === true && Array.isArray(tableData.data) && tableData.data.length > 0) {
      newState.selectedItems = tableData.data.map((currentItem) => currentItem._id);
    } else {
      newState.selectedItems = [];
    }
    setState(newState);
  };

  const toggleSelectRow = (event, itemId) => {
    const newState = { ...state };
    const isChecked = event.target.checked;
    const elementId = state.selectedItems.findIndex((current) => current === itemId);
    if (isChecked === true && elementId === -1) {
      newState.selectedItems.push(itemId);
    } else if (isChecked === false && elementId > -1) {
      newState.selectedItems.splice(elementId, 1);
    }
    setState(newState);
  };

  const toggleExportField = (field) => (event) => {
    const isChecked = event.target.checked;
    const exportFields = [...selectedExportFields];
    if (isChecked) {
      exportFields.push(field);
    } else {
      const index = exportFields.findIndex((exportField) => exportField.value === field.value);
      exportFields.splice(index, 1); // if the field is deselected, remove it from the array
    }
    setSelectedExportFields(exportFields);
  };
  

  const isRowSelected = (itemId) => (
    state.selectedItems.findIndex((current) => current === itemId) > -1
  );

  const processData = (currentTableData) => {
    const filters = {};
    if (typeof currentTableData.search === 'string' && currentTableData.search.length > 0) filters.search = currentTableData.search;
    if (currentTableData.customFilter) filters.customFilter = currentTableData.customFilter;
    const newTableData = { ...currentTableData };
    try {
      sectionService.getList(
        {
          paginate: currentTableData.paginate,
          page: currentTableData.page,
          order: currentTableData.order,
          sort: currentTableData.sort,
          searchFor: currentTableData.searchParam,
          filters,
          okCallback: (response) => {
            try {
              newTableData.total = parseInt(response?.headers?.total || 0, 10);
              const mappedData = response.data.map(mapListFn) || [];
              setTableData({ ...newTableData, ...{ data: mappedData } });
            } catch (e) {
              throw new Error(e);
            }
          },
          koCallback: () => {
            setTableData({ ...tableData }, { ...{ data: [] } });
          },
        },
      );
    } catch (e) {
      throw new Error(e);
    }
  };

  const onChangeOrderSort = (value) => {
    const newTableData = { ...tableData, ...value };
    newTableData.page = 1;
    newTableData.data = null;
    setTableData(newTableData);
    setState({ selectedItems: [] });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const newTableData = { ...tableData };
    let mustRelocate = false;
    const currentParams = {
      paginate: queryParams.get('paginate'),
      page: queryParams.get('page'),
      order: queryParams.get('order'),
      sort: queryParams.get('sort'),
      search: queryParams.get('search'),
    };

    if (currentParams.paginate !== null) {
      currentParams.paginate = parseInt(currentParams.paginate, 10);
    }
    if (currentParams.page !== null) {
      currentParams.page = parseInt(currentParams.page, 10);
    }

    if (currentParams.search === null) {
      currentParams.search = '';
    }

    if (tableData.paginate === null) {
      if (currentParams.paginate !== null) newTableData.paginate = currentParams.paginate;
      else newTableData.paginate = 10;
    }
    if (currentParams.paginate !== newTableData.paginate) {
      currentParams.paginate = newTableData.paginate;
      if (mustRelocate === false) mustRelocate = true;
    }

    if (tableData.page === null) {
      if (currentParams.page !== null) newTableData.page = currentParams.page;
      else newTableData.page = 1;
    }
    if (currentParams.page !== newTableData.page) {
      currentParams.page = newTableData.page;
      if (mustRelocate === false) mustRelocate = true;
    }

    if (tableData.order === null) {
      if (currentParams.order !== null) newTableData.order = currentParams.order;
      else if (initialSortOrder) newTableData.order = initialSortOrder;
      else newTableData.order = 'asc';
    }
    if (currentParams.order !== newTableData.order) {
      currentParams.order = newTableData.order;
      if (mustRelocate === false) mustRelocate = true;
    }

    if (tableData.sort === null) {
      if (currentParams.sort !== null) newTableData.sort = currentParams.sort;
      else newTableData.sort = initialSortField;
    }
    if (currentParams.sort !== newTableData.sort) {
      currentParams.sort = newTableData.sort;
      if (mustRelocate === false) mustRelocate = true;
    }

    if (tableData.search === null) {
      if (currentParams.search !== null) newTableData.search = currentParams.search;
      else newTableData.search = '';
    }
    if (currentParams.search !== newTableData.search) {
      currentParams.search = newTableData.search;
      if (mustRelocate === false) mustRelocate = true;
    }

    if (tableData.custom === null) {
      if (currentParams.customFilter !== null) {
        newTableData.customFilter = currentParams.customFilter;
      } else newTableData.customFilter = null;
    }
    if (currentParams.customFilter !== newTableData.customFilter) {
      currentParams.customFilter = newTableData.customFilter;
      if (mustRelocate === false) mustRelocate = true;
    }

    if (mustRelocate) {
      const mappedQueryParams = dataToQueryParams(newTableData);
      navigate(`${sectionPath}${mappedQueryParams}`, { replace: true });
    }
    if (newTableData.data === null) processData(newTableData);
  }, [
    tableData.paginate,
    tableData.page,
    tableData.order,
    tableData.sort,
    tableData.search,
    tableData.customFilter,
  ]);

  const columns = () => {
    const selectColumn = {
      key: 'select',
      label: <CFormCheck
        disabled={
          tableData.data === null
          || (Array.isArray(tableData.data) && tableData.data.length <= 0)
        }
        checked={(Array.isArray(tableData.data) && tableData.data.length > 0
          && tableData.data.length === state.selectedItems.length
        )}
        onChange={(event) => toggleSelectAllRows(event)}
      />,
      _style: { width: '1%' },
      _props: { scope: 'col' },
    };
    return [selectColumn].concat(buildColumnsFn());
  };

  const renderTableData = () => {
    if (Array.isArray(tableData.data) && tableData.data.length > 0) {
      return tableData.data.map((item) => {
        const selectColumn = {
          select: (
            <CFormCheck
              checked={isRowSelected(item._id)}
              onChange={(event) => toggleSelectRow(event, item._id)}
            />
          ),
        };
        const rowsData = buildRowsFn(item);
        return { ...selectColumn, ...rowsData };
      });
    }
    return [];
  };

  const editItem = (itemId) => {
    navigate(`${sectionPath}/${itemId}`);
  };

  const applyFilters = (data) => {
    if (data.listFilter === tableData.search && !data.customFilter) return;
    const newTableData = { ...tableData };
    newTableData.page = 1;
    newTableData.search = data.listFilter;
    if (data.customFilter) {
      newTableData.customFilter = data.customFilter;
    }
    if (filterOptions && filterOptions.length > 0) {
      if (selectedOptionFilter) {
        newTableData.searchParam = selectedOptionFilter;
      }
    }
    newTableData.data = null;
    setState({ selectedItems: [] });
    reset({ listFilter: data.listFilter });
    setTableData(newTableData);
  };

  const handleCreateNew = (data) => {
    if (showCreateModal === true && creationAction.executing === false) {
      setCreationAction({ error: null, executing: true });
      const creationData = (typeof formatCreationData === 'function') ? formatCreationData(data) : { ...data };
      sectionService.addItem(
        creationData,
        (response) => {
          setCreationAction({ ...creationAction, executing: false });
          navigate(`${sectionPath}/${response?.data?._id}`);
        },
        (error) => {
          setCreationAction({ error, executing: false });
        },
      );
    }
  };

  const showCreationModalAndClearModel = () => {
    clearCreationModel();
    setCreationAction({ error: null, executing: false });
    setShowCreateModal(true);
  };

  const closeCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleDeleteItems = () => {
    setDeleteAction({ error: null, executing: true, success: null });
    sectionService.deleteItem([...state.selectedItems], () => {
      const newTableData = { ...tableData };
      newTableData.data = null;
      setState({ selectedItems: [] });
      setDeleteAction({ error: null, executing: false, success: true });
      setTableData(newTableData);
      processData(newTableData);
    }, (error) => {
      setDeleteAction({ error, executing: false, success: false });
    });
  };

  const handleExportItems = () => {
    setExportAction({ error: null, executing: true, success: null });
    // TODO
  };

  const showDeleteModalAndClearModel = () => {
    setDeleteAction({ error: null, executing: false, success: null });
    setShowDeleteModal(true);
  };

  const showExportModalAndClearModel = (isInvoicingExport = false) => {
    setExportAction({ error: null, executing: false, success: null });
    setShowExportModal(!isInvoicingExport);
    setShowInvoicingExportModal(isInvoicingExport);
  };

  const renderDeleteModal = () => {
    let renderLabel = `Sei sicuro di voler eliminare ${state.selectedItems.length === 1 ? 'l\'elemento selezionato' : 'gli elementi selezionati'}? Questa azione non può essere annullata.`;
    if (deleteAction.success === true) {
      renderLabel = 'Operazione completata con successo.';
    } else if (deleteAction.success === false) {
      // TODO: verificare possibili errori, nessuno trovato al momento.
      renderLabel = "Si è verificato un errore nell'esecuzione dell'operazione.";
    }
    return (
      <CModal backdrop="static" visible={showDeleteModal}>
        <CModalHeader closeButton={false}>
          <CModalTitle>{state.selectedItems.length === 1 ? 'Eliminare l\'elemento' : 'Eliminare gli elementi'}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {deleteAction.executing === true ? <AppLoadingSpinner /> : renderLabel}
        </CModalBody>
        <CModalFooter>
          <CButton color="danger" disabled={deleteAction.executing === true} onClick={() => setShowDeleteModal(false)}>
            {deleteAction.success === null ? 'Annulla' : 'Chiudi'}
          </CButton>
          {deleteAction.success === null && (
            <CButton color="primary" disabled={deleteAction.executing === true} onClick={() => handleDeleteItems()}>Si</CButton>
          )}
        </CModalFooter>
      </CModal>
    );
  };

  const onSubmitInvoicingExport = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const data = Object.fromEntries(formData.entries());

    // Check if the dates are valid
    if (data.from_date && data.to_date) {
      const validation = validateDateRange(data.from_date, data.to_date);
      if (!validation.isValid) {
        return event.target[validation.invalidField].focus();
      }
    }

    const okGetInvoicingCsv = (response) => {
      if (response.status === 204) {
        return toast.error('Esportazione vuota. Riprovare con altre date.');
      }
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${invoicingExportModalProps.csvFileName || 'fatture'}_${data?.from_date}_${data?.to_date}.csv`;
      link.click();
      link.remove();
      setShowInvoicingExportModal(false);
      toast.success('Esportazione completata con successo.');
    };

    const koGetInvoicingCsv = (error) => {
      setShowInvoicingExportModal(false);
      toast.error('Si è verificato un errore nell\'esportazione dei dati.');
    };

    sectionService.getInvoicingCsv(data, okGetInvoicingCsv, koGetInvoicingCsv);
  };
    

  const renderExportModal = () => {
    if (showInvoicingExportModal) {
      return (
        <InvoicingExportModal
          visible={showInvoicingExportModal}
          onClose={() => setShowInvoicingExportModal(false)}
          onSubmit={onSubmitInvoicingExport}
          sectionService={sectionService}
          {...invoicingExportModalProps}
        />
      );
    }

    const exportFields = getExportFields(`${sectionId}List`);
    let renderLabel = `Seleziona i campi da esportare nel file CSV.`;
    if (exportAction.success === true) {
      renderLabel = 'Operazione completata con successo.';
    } else if (exportAction.success === false) {
      renderLabel = "Si è verificato un errore nell'esecuzione dell'operazione.";
    }
    
    return (
      <CModal backdrop="static" visible={showExportModal} alignment="center">
        <CModalHeader closeButton={false}>
          <CModalTitle>{`Esportazione personalizzata ${sectionTitle}`}</CModalTitle>
        </CModalHeader>
        <CModalBody className="d-flex flex-column gap-3">
          {exportAction.executing === true ? (
            <AppLoadingSpinner />
          ) : (
            renderLabel
          )}
          <div className='d-flex flex-wrap gap-1'>
            {exportFields.map(mapExportFields)}
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="danger"
            disabled={exportAction.executing === true}
            onClick={() => setShowExportModal(false)}
          >
            {exportAction.success === null ? "Annulla" : "Chiudi"}
          </CButton>
          {exportAction.success === null && (
            <CButton
              color="primary"
              disabled={exportAction.executing === true}
              onClick={handleCsvExport}
            >
              Esporta
            </CButton>
          )}
        </CModalFooter>
      </CModal>
    );
  };

  const handleCsvExport = () => {
    const selectedFields = [...selectedExportFields];
    const okGetCsv = (response) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${sectionTitle}.csv`;
      link.click();
      link.remove();
      setShowExportModal(false);
      setShowInvoicingExportModal(false);
      toast.success('Esportazione completata con successo.');
    };

    const koGetCsv = (error) => {
      // setExportAction({ error, executing: false, success: false });
      setShowExportModal(false);
      setShowInvoicingExportModal(false);
      toast.error('Si è verificato un errore nell\'esportazione dei dati.');
    };

    sectionService.getCsv(okGetCsv, koGetCsv);
  };

  const mapExportFields = (field, index) => (
    <div key={index} style={{width: "200px"}}>
      <CFormCheck
        inline
        id={field.value}
        value={field.value}
        label={field.label}
        onChange={toggleExportField(field)}
      />  
    </div>
  );

  const handleOnChangeInput = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleCsvImport = (event) => {
    if (!selectedFile) return;
    const fileToImport = {
      file: selectedFile,
      type: selectedFile.type,
    };

    const savePromise = new Promise((resolve, reject) => {
      const okImportCsv = (response) => {
        resolve();
        const newTableData = { ...tableData };
        newTableData.data = null;
        setSelectedFile(null);
        inputRef.current.value = null;
        setState({ selectedItems: [] });
        setTableData(newTableData);
        processData(newTableData);
      };
      const koImportCsv = (error) => {
        console.log("koImportCsv", error);
        reject(new Error(error.data.message));
      };
      try {
        sectionService.importCsv(fileToImport, okImportCsv, koImportCsv);
      } catch (error) {
        throw new Error(error);
      }
    });

    toast.promise(savePromise, {
      loading: "Importazione in corso...",
      success: "Importazione completata con successo",
      error: (err) => `Importazione fallita: ${err.message || err}`,
    });
  };

  const handleAdvancedExport = (data) => {
    const commonParams = {
      from_date: data.from_date,
      to_date: data.to_date,
    };

    switch(data.exportType) {
      case 'invoicing':
        sectionService.getInvoicingCsv({
          ...commonParams,
          threshold: data.threshold
        }, (response) => handleExportSuccess(response, 'invoicing', data.from_date, data.to_date), handleExportError);
        break;
      case 'amounts':
        sectionService.getAmountsCsv(commonParams, (response) => handleExportSuccess(response, 'amounts', data.from_date, data.to_date), handleExportError);
        break;
      case 'bookings':
        sectionService.getWineriesBookingsCsv(commonParams, (response) => handleExportSuccess(response, 'bookings', data.from_date, data.to_date), handleExportError);
        break;
      default:
        toast.error('Tipo di esportazione non valido');
    }

    setShowAdvancedExportModal(false);
  };

  const handleExportSuccess = (response, exportType, fromDate, toDate) => {
    if (response.status === 204) {
      return toast.error('Esportazione vuota. Riprovare con altre date.');
    }
    
    let fileType = 'text/csv';
    let fileExtension = 'csv';
    let fileName = '';
    
    if (response.headers['content-type'] === 'application/zip') {
      fileType = 'application/zip';
      fileExtension = 'zip';
    }

    switch(exportType) {
      case 'invoicing':
        fileName = `dati_fatturazione_cantine_${fromDate}-${toDate}`;
        break;
      case 'amounts':
        fileName = `dovuto_cantine_${fromDate}-${toDate}`;
        break;
      case 'bookings':
        fileName = `prenotazioni_generali_cantine_${fromDate}-${toDate}`;
        break;
      default:
        fileName = `export_${new Date().toISOString()}`;
    }
    
    const blob = new Blob([response.data], { type: fileType });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `${fileName}.${fileExtension}`;
    link.click();
    link.remove();
    toast.success('Esportazione completata con successo.');
  };

  const handleExportError = (error) => {
    console.error('Errore durante l\'esportazione:', error);
    toast.error('Si è verificato un errore nell\'esportazione dei dati.');
  };

  const renderAdvancedExportModal = () => {
    if (!isAdvancedExportable) return null;

    const [formData, setFormData] = useState({
      exportType: 'invoicing',
      from_date: '',
      to_date: '',
      threshold: '',
    });

    const today = new Date().toISOString().split('T')[0];

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      
      // Check if the dates are valid
      if (formData.from_date && formData.to_date) {
        const validation = validateDateRange(formData.from_date, formData.to_date);
        if (!validation.isValid) {
          return e.target[validation.invalidField].focus();
        }
      }

      handleAdvancedExport(formData);
    };

    return (
      <CModal
        visible={showAdvancedExportModal}
        onClose={() => setShowAdvancedExportModal(false)}
        backdrop="static"
      >
        <CModalHeader closeButton>
          <CModalTitle>{advancedExportModalProps.title}</CModalTitle>
        </CModalHeader>
        <CModalBody style={{ whiteSpace: 'pre-wrap' }}>
          <p>{advancedExportModalProps.descriptions[formData.exportType]}</p>
          <CForm id="advancedExportForm" onSubmit={handleSubmit}>
            {advancedExportModalProps.fields.map((field, index) => (
              <div key={index} className="mb-3">
                {field.id !== 'threshold' || formData.exportType === 'invoicing' ? (
                  <>
                    <CFormLabel htmlFor={field.id}>{field.label}</CFormLabel>
                    {field.type === 'select' ? (
                      <CFormSelect
                        name={field.id}
                        id={field.id}
                        options={field.options}
                        required={field.required}
                        onChange={handleInputChange}
                        value={formData[field.id]}
                      />
                    ) : (
                      <CFormInput
                        type={field.type}
                        name={field.id}
                        id={field.id}
                        required={field.required}
                        onChange={handleInputChange}
                        value={formData[field.id]}
                        min={field.id === 'threshold' ? 0 : undefined}
                        max={field.id === 'from_date' || field.id === 'to_date' ? today : undefined}
                      />
                    )}
                  </>
                ) : null}
              </div>
            ))}
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton 
            color="danger" 
            onClick={() => setShowAdvancedExportModal(false)}
          >
            Annulla
          </CButton>
          <CButton 
            color="primary" 
            type="submit" 
            form="advancedExportForm"
          >
            {advancedExportModalProps.submitButtonText}
          </CButton>
        </CModalFooter>
      </CModal>
    );
  };

  return (
    <>
      <h1 className="list-title">{sectionTitle}</h1>
      {/* CUSTOM FILTER */}
      <CustomFilter tableData={tableData} applyFilters={applyFilters} />
      {/* CUSTOM FILTER */}
      <CRow className="align-items-end mb-4">
        <CCol md={12} lg={6}>
          <CForm onSubmit={handleSubmit(applyFilters)}>
            <CFormLabel htmlFor="list-filter">Filtro</CFormLabel>
            <CInputGroup>
              {filterOptions && filterOptions.length > 0 && (
                <CFormSelect
                style={{
                  marginRight: '5px',
                }}
                  name="customFilter"
                  id="customFilter"
                  disabled={tableData.data === null}
                  onChange={(e) => {
                    const customFilter = e.target.options[e.target.selectedIndex].value;
                    setSelectedOptionFilter(customFilter);
                  }}
                  aria-label="Filtro personalizzato"
                  options={filterOptions}
                />
              )}
              <Controller
                name="listFilter"
                control={control}
                render={({ field }) => (
                  <CFormInput
                    {...field}
                    disabled={tableData.data === null || (filterOptions && !selectedOptionFilter)}
                    type="text"
                    placeholder="Inserisci le parole chiave"
                    aria-label="Filtro"
                    aria-describedby="filter-button"
                  />
                )}
              />
              <CButton
                disabled={tableData.data === null }
                type="submit"
                name="filter"
                color="primary"
                id="filter-button"
              >
                Filtra
              </CButton>
              <CButton
                disabled={
                  tableData.data === null || tableData?.search?.length <= 0
                }
                type="button"
                name="reset"
                color="danger"
                id="filter-button-clear"
                onClick={() => applyFilters({ listFilter: "" })}
              >
                Cancella
              </CButton>
            </CInputGroup>
          </CForm>
        </CCol>
        <CCol md={12} lg={6} className="list-actions mt-2">
          {permissions.create === true && (
            <CButton
              color="primary"
              disabled={tableData.data === null}
              onClick={() => showCreationModalAndClearModel()}
            >
              <CIcon icon={cilPlus} className="icon-button" />
              Nuovo
            </CButton>
          )}
          {permissions.edit === true && (
            <CButton
              color="primary"
              disabled={tableData === null || state.selectedItems.length !== 1}
              onClick={() =>
                state.selectedItems[0] && editItem(state.selectedItems[0])
              }
            >
              <CIcon icon={cilPencil} className="icon-button" />
              Modifica
            </CButton>
          )}
          {permissions.delete === true && (
            <CButton
              color="primary"
              disabled={
                tableData.data === null || state.selectedItems.length === 0
              }
              onClick={() => showDeleteModalAndClearModel()}
            >
              <CIcon icon={cilTrash} className="icon-button" />
              Elimina
            </CButton>
          )}
          {(permissions.group === "superadmin" || permissions.group === "admin") && isExportable && (
            <CButton
              color="primary"
              style={isInvoicingExportable ? { marginRight: 0 } : {}}
              disabled={tableData.data === null || !isExportable}
              // onClick={showExportModalAndClearModel} 
              onClick={handleCsvExport} // Momentaneamente disabilitato l'export custom
            >
              <CIcon icon={cilFile} className="icon-button" />
              Esporta
            </CButton>
          )}
          {(permissions.group === "superadmin") && isInvoicingExportable && (
            <CButton
              color="primary"
              disabled={tableData.data === null || !isExportable}
              onClick={() => showExportModalAndClearModel(true)}
            >
              <CIcon icon={cilFile} className="icon-button"  />
              {invoicingExportModalProps.exportButtonText || 'Esporta dati fatturazione'}
            </CButton>
          )}
          {(permissions.group === "superadmin") && isAdvancedExportable && (
            <CButton
              color="primary"
              disabled={tableData.data === null || !isAdvancedExportable}
              onClick={() => setShowAdvancedExportModal(true)}
            >
              <CIcon icon={cilFile} className="icon-button" />
              Esporta dati avanzati
            </CButton>
          )}
        </CCol>
      </CRow>
      <CRow className="d-flex justify-content-between mb-4">
        <CCol md={12} lg={6} />
        <CCol md={12} lg={5} >
          {(permissions.group === "admin" || permissions.group === "superadmin") && (
            <>
              {(sectionPath === "/tours" || sectionPath === "/winery") && (
                <CForm className='d-flex align-items-center gap-3 w-100'>
                  <CFormInput
                    type="file"
                    id="csv-file-input"
                    accept=".csv"
                    onChange={handleOnChangeInput}
                    ref={inputRef}
                  />
                  <CButton
                  className='d-flex align-items-center'
                    color="primary"
                    onClick={handleCsvImport}
                    disabled={!selectedFile}
                  >
                    <CIcon icon={cilCloudDownload} className="icon-button" />
                    Importa
                  </CButton>
                </CForm>
              )}
            </>
          )}
        </CCol>
      </CRow>
      <div className="table-placeholder">
        <div className={scrollable === true ? " table-scrollable" : ""}>
          <AppTable
            columns={columns()}
            items={renderTableData()}
            loading={tableData.data === null}
            orderBy={tableData.order}
            sortBy={tableData.sort}
            onChangeOrderSort={onChangeOrderSort}
            rowAction={editItem}
          />
        </div>
      </div>
      <Pagination
        tableData={tableData}
        setSelectedItems={setState}
        setTableData={setTableData}
      />
      <CModal size="xl" backdrop="static" visible={showCreateModal}>
        <CModalHeader closeButton={false}>
          <CModalTitle>{creationTitle}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          {creationAction?.error?.data?.message && (
            <CRow>
              <CCol>
                <CAlert color="danger" dismissible>
                  <CAlertHeading tag="h4">Errore nella creazione</CAlertHeading>
                  <p>{creationAction?.error?.data?.message}</p>
                </CAlert>
              </CCol>
            </CRow>
          )}
          <CForm id="creationForm" onSubmit={evalCreation(handleCreateNew)}>
            {creationBodyFn()}
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="secondary"
            disabled={creationAction.executing === true}
            onClick={() => closeCreateModal()}
          >
            Annulla
          </CButton>
          <CButton
            type="submit"
            disabled={creationAction.executing === true}
            form="creationForm"
            color="primary"
          >
            Crea
          </CButton>
        </CModalFooter>
      </CModal>
      {renderDeleteModal()}
      {renderExportModal()}
      {renderAdvancedExportModal()}
    </>
  );
}

AppList.propTypes = {
  scrollable: PropTypes.bool,
  sectionId: PropTypes.string.isRequired,
  SectionServiceClass: PropTypes.func.isRequired,
  sectionPath: PropTypes.string.isRequired,
  sectionTitle: PropTypes.string,
  mapListFn: PropTypes.func.isRequired,
  buildColumnsFn: PropTypes.func.isRequired,
  buildRowsFn: PropTypes.func.isRequired,
  creationTitle: PropTypes.string.isRequired,
  creationBodyFn: PropTypes.func,
  evalCreation: PropTypes.func,
  clearCreationModel: PropTypes.func,
  formatCreationData: PropTypes.func,
  initialSortField: PropTypes.string,
  CustomFilter: PropTypes.func,
  isExportable: PropTypes.bool,
  initialSortOrder: PropTypes.string,
  filterOptions: PropTypes.array,
  invoicingExportModalProps: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    fields: PropTypes.arrayOf(PropTypes.object),
    submitButtonText: PropTypes.string,
    csvFileName: PropTypes.string,
  }),
  isAdvancedExportable: PropTypes.bool,
  advancedExportModalProps: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    fields: PropTypes.arrayOf(PropTypes.object),
    submitButtonText: PropTypes.string,
  }),
};

AppList.defaultProps = {
  scrollable: false,
  sectionTitle: 'Lista',
  creationBodyFn: null,
  evalCreation: null,
  clearCreationModel: null,
  formatCreationData: undefined,
  initialSortField: 'name',
  CustomFilter: () => {},
  isExportable: false,
  isInvoicingExportable: false,
  initialSortOrder: 'asc',
  filterOptions: null,
  invoicingExportModalProps: {
    title: 'Esportazione dati fatturazione',
    description: 'Seleziona l\'intervallo di date per l\'export dei dati di fatturazione.',
    fields: [
      { id: 'from_date', label: 'Dal', type: 'date', required: true },
      { id: 'to_date', label: 'Al', type: 'date', required: true, max: new Date().toISOString().split("T")[0] },
    ],
    submitButtonText: 'Esporta',
  },
  isAdvancedExportable: false,
  advancedExportModalProps: {
    title: 'Esportazione dati avanzati',
    description: 'Seleziona il tipo di esportazione che desideri effettuare.',
    fields: [
      {
        id: 'exportType',
        label: 'Tipo di esportazione',
        type: 'select',
        options: [
          { value: 'invoicing', label: 'Fatturato cantine' },
          { value: 'amounts', label: 'Dovuto cantine' },
          { value: 'bookings', label: 'Prenotazioni generali cantine' }
        ],
        required: true,
      },
      {
        id: 'from_date',
        label: 'Data inizio',
        type: 'date',
        required: true,
      },
      {
        id: 'to_date',
        label: 'Data fine',
        type: 'date',
        required: true,
        max: new Date().toISOString().split('T')[0],
      },
      {
        id: 'threshold',
        label: 'Soglia minima',
        type: 'number',
        required: true,
        min: 0,
        conditional: (values) => values.exportType === 'invoicing',
      },
    ],
    submitButtonText: 'Esporta',
  },
};

export default AppList;
