/* eslint-disable */
// React, Redux
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

// React Hook Form
import { useForm, Controller } from "react-hook-form";

// React Select
import AsyncSelect from "react-select/async";

// React Beautiful DnD
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// CoreUI Components
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CFormLabel,
  CRow,
  CSpinner,
  CTooltip,
} from "@coreui/react";

// CoreUI Icons
import CIcon from "@coreui/icons-react";
import { cilSave, cilTrash } from "@coreui/icons";

// Utils
import toast from "react-hot-toast";

// Services
import SponsoredToursService from "src/services/api/SponsoredToursService";

const sponsoredToursService = new SponsoredToursService();

const MAX_TOURS = 12;

function SponsoredToursList() {
  const [selectedTours, setSelectedTours] = useState([]);
  const [orderedTours, setOrderedTours] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const userData = useSelector((state) => state.user.value);

  const {
    control,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    // Al caricamento del componente, recupera i tour sponsorizzati già ordinati
    fetchSponsoredTours();
  }, []);

  const fetchSponsoredTours = () => {
    setIsLoading(true);
    const okCallback = (response) => {
      if (response && response.data) {
        // Map API response to the format expected by react-select
        const tours = response.data.map((tour) => ({
          value: tour.tour_id,
          label: tour.tour_name,
          order: tour.order,
        }));

        // Imposta sia i tour ordinati che quelli selezionati
        setOrderedTours(tours);
        setSelectedTours(tours);

        // Aggiorna anche il valore del form
        setValue("new_tour_select", tours);
      }
      setIsLoading(false);
    };

    const koCallback = (error) => {
      console.error("Errore nel recupero dei tour sponsorizzati:", error);
      toast.error("Errore nel recupero dei tour sponsorizzati");
      setIsLoading(false);
    };

    sponsoredToursService.getSponsoredTours(okCallback, koCallback);
  };

  const hasEditPermission = () => {
    const permissions = userData.permissions || [];
    const userGroup = userData.user_group || "";
    return (
      permissions.includes(`sponsored_tours_edit`) || userGroup === "superadmin"
    );
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "12px 16px",
    margin: `0 0  8px 0`,
    borderRadius: 12,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: isDragging
      ? "0 4px 8px rgba(0,0,0,0.1)"
      : "0 2px 4px rgba(0,0,0,0.05)",
    background: isDragging ? "#f8f9fa" : "white",
    border: "1px solid #e9ecef",
    transition: "all 0.2s ease",
    cursor: "move",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#f1f3f5" : "#f8f9fa",
    padding: 8,
    borderRadius: 12,
    minHeight: "300px",
    maxHeight: "400px",
    overflowY: "auto",
    border: "2px dashed",
    borderColor: isDraggingOver ? "#339af0" : "#e9ecef",
    transition: "all 0.2s ease",
  });

  const loadTours = (filter) =>
    new Promise((resolve) => {
      setIsLoading(true);
      const okGetTours = (response) => {
        let responseData = [];
        if (Array.isArray(response.data) && response.data.length > 0) {
          responseData = response.data.map((currentItem) => {
            return {
              value: currentItem.tour_id,
              label: currentItem.tour_name,
            };
          });
        }
        setIsLoading(false);
        resolve(responseData);
      };

      const koGetTours = () => {
        toast.error("Errore nel caricamento dei tour");
        setIsLoading(false);
        resolve([]);
      };

      sponsoredToursService.getTours(filter, okGetTours, koGetTours);
    });

  const onChangeSelectedOption = (selectedOptions) => {
    if (!selectedOptions) {
      // Se non ci sono opzioni selezionate, svuota entrambe le liste
      setSelectedTours([]);
      setOrderedTours([]);
      setValue("new_tour_select", []);
      return;
    }

    // Ottieni i valori dei tour attualmente selezionati
    const selectedValues = selectedOptions.map((option) => option.value);

    // Filtra i tour ordinati per mantenere solo quelli ancora selezionati
    const filteredOrderedTours = orderedTours.filter((tour) =>
      selectedValues.includes(tour.value)
    );

    // Trova i nuovi tour che non sono ancora nella lista ordinata
    const newTours = selectedOptions.filter(
      (tour) =>
        !filteredOrderedTours.some(
          (orderedTour) => orderedTour.value === tour.value
        )
    );

    // Verifica se l'aggiunta dei nuovi tour supererebbe il limite MAX_TOURS
    if (filteredOrderedTours.length + newTours.length > MAX_TOURS) {
      // Mostra un toast di warning
      toast.error(
        `Limite massimo di ${MAX_TOURS} tour raggiunto. Impossibile aggiungere ulteriori tour.`
      );

      // Calcola quanti tour possiamo effettivamente aggiungere
      const availableSlots = MAX_TOURS - filteredOrderedTours.length;

      // Se ci sono slot disponibili, aggiungi solo i primi N tour fino a raggiungere il limite
      if (availableSlots > 0) {
        const toursToAdd = newTours.slice(0, availableSlots);

        const updatedOrderedTours = [
          ...filteredOrderedTours,
          ...toursToAdd.map((tour, index) => ({
            ...tour,
            order: filteredOrderedTours.length + index,
          })),
        ];

        setOrderedTours(updatedOrderedTours);

        // Aggiorna anche la selezione per riflettere solo i tour che sono stati effettivamente aggiunti
        const updatedSelectedTours = [
          ...selectedOptions.filter((tour) =>
            filteredOrderedTours.some(
              (orderedTour) => orderedTour.value === tour.value
            )
          ),
          ...toursToAdd,
        ];

        setSelectedTours(updatedSelectedTours);
        setValue("new_tour_select", updatedSelectedTours);
        return;
      } else {
        // Se non ci sono slot disponibili, mantieni solo i tour già selezionati
        setSelectedTours(
          selectedOptions.filter((tour) =>
            filteredOrderedTours.some(
              (orderedTour) => orderedTour.value === tour.value
            )
          )
        );
        setValue(
          "new_tour_select",
          selectedOptions.filter((tour) =>
            filteredOrderedTours.some(
              (orderedTour) => orderedTour.value === tour.value
            )
          )
        );
        return;
      }
    }

    if (newTours.length > 0) {
      // Aggiungi i nuovi tour alla fine della lista ordinata
      const updatedOrderedTours = [
        ...filteredOrderedTours,
        ...newTours.map((tour, index) => ({
          ...tour,
          order: filteredOrderedTours.length + index,
        })),
      ];

      setOrderedTours(updatedOrderedTours);
    } else if (filteredOrderedTours.length < orderedTours.length) {
      // Se sono stati rimossi dei tour dalla selezione
      setOrderedTours(filteredOrderedTours);
    }

    // Aggiorna lo stato della selezione
    setSelectedTours(selectedOptions);
    setValue("new_tour_select", selectedOptions);
  };

  const removeTourFromOrdered = (tourId) => {
    // Rimuovi il tour dalla lista ordinata
    const updatedOrderedTours = orderedTours.filter(
      (tour) => tour.value !== tourId
    );

    // Aggiorna gli ordini dei tour rimanenti
    const reorderedTours = updatedOrderedTours.map((tour, index) => ({
      ...tour,
      order: index,
    }));

    // Rimuovi il tour anche dalla lista dei selezionati
    const updatedSelectedTours = selectedTours.filter(
      (tour) => tour.value !== tourId
    );

    // Aggiorna entrambi gli stati
    setOrderedTours(reorderedTours);
    setSelectedTours(updatedSelectedTours);
    setValue("new_tour_select", updatedSelectedTours);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const reorderedTours = Array.from(orderedTours);
    const [removed] = reorderedTours.splice(result.source.index, 1);
    reorderedTours.splice(result.destination.index, 0, removed);

    // Aggiorna gli ordini dopo il riordinamento
    const updatedOrderedTours = reorderedTours.map((tour, index) => ({
      ...tour,
      order: index,
    }));

    setOrderedTours(updatedOrderedTours);
  };

  const saveSponsoredTours = () => {
    if (orderedTours.length === 0) {
      toast.error("Nessun tour da salvare");
      return;
    }

    setIsSaving(true);
    const toursToSave = orderedTours.map((tour, index) => ({
      tour_id: tour.value,
      tour_name: tour.label,
      order: index,
    }));

    const okCallback = () => {
      setIsSaving(false);
      toast.success("Ordinamento dei tour salvato con successo");
    };

    const koCallback = (error) => {
      console.error("Errore nel salvataggio dell'ordinamento:", error);
      setIsSaving(false);
      toast.error("Errore nel salvataggio dell'ordinamento");
    };

    sponsoredToursService.saveSponsoredTours(
      toursToSave,
      okCallback,
      koCallback
    );
  };

  return (
    <div>
      <CCard className="mb-4">
        <CCardHeader>
          <h2>Gestione Tour Sponsorizzati</h2>
          <p className="text-medium-emphasis">
            Seleziona e ordina i tour da mostrare in evidenza sul sito (max{" "}
            {MAX_TOURS} tour)
          </p>
        </CCardHeader>
        <CCardBody>
          <CRow md={{ cols: 2, gutter: 4 }}>
            <CCol md="6">
              <h4>Selezione Tour</h4>
              <p className="text-medium-emphasis small mb-3">
                Seleziona i tour da aggiungere alla lista dei tour sponsorizzati
              </p>

              <Controller
                name="new_tour_select"
                control={control}
                rules={{ required: false }}
                render={({ field }) => (
                  <>
                    <CFormLabel htmlFor="new_tour_select">
                      Seleziona Tour da Aggiungere
                    </CFormLabel>
                    <AsyncSelect
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          maxHeight: "350px",
                          overflowY: "auto",
                        }),
                      }}
                      defaultOptions
                      loadOptions={loadTours}
                      inputId="new_tour_select"
                      isMulti
                      {...field}
                      isClearable
                      isLoading={isLoading}
                      placeholder={"Cerca e seleziona uno o più tour"}
                      onChange={onChangeSelectedOption}
                      value={selectedTours}
                      noOptionsMessage={() => "Nessun tour trovato"}
                      loadingMessage={() => "Caricamento tour..."}
                    />
                  </>
                )}
              />

              <div className="mt-4">
                <h5>Informazioni</h5>
                <ul>
                  <li>
                    L'ordine stabilito determina la priorità di visualizzazione
                    dei tour
                  </li>
                  <li>Puoi riordinare i tour tramite drag & drop</li>
                  <li>Ricordati di salvare le modifiche</li>
                </ul>
              </div>
            </CCol>
            <CCol md="6">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>
                  Ordinamento Tour ({orderedTours.length}/{MAX_TOURS})
                </h4>
                {hasEditPermission() && (
                  <CButton
                    color="primary"
                    onClick={saveSponsoredTours}
                    disabled={orderedTours.length === 0 || isSaving}
                >
                  {isSaving ? (
                    <>
                      <CSpinner size="sm" className="me-2" /> Salvataggio...
                    </>
                  ) : (
                      <>
                        <CIcon icon={cilSave} className="me-2" /> Salva
                        Ordinamento
                      </>
                    )}
                  </CButton>
                )}
              </div>

              <p className="text-medium-emphasis small mb-3">
                Trascina i tour per modificare l'ordine di visualizzazione sul
                sito
              </p>

              {orderedTours.length > 0 ? (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="sponsored-tours">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {orderedTours.map((tour, index) => (
                          <Draggable
                            key={tour.value}
                            draggableId={tour.value}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div className="d-flex align-items-center flex-grow-1">
                                  <div
                                    style={{
                                      width: "28px",
                                      height: "28px",
                                      borderRadius: "50%",
                                      background: "#e7f5ff",
                                      color: "#339af0",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontWeight: "600",
                                      marginRight: "12px",
                                      fontSize: "0.875rem",
                                    }}
                                  >
                                    {index + 1}
                                  </div>
                                  <div style={{ fontWeight: "500" }}>
                                    {tour.label}
                                  </div>
                                </div>
                                <CTooltip content="Rimuovi tour">
                                  <CButton
                                    color="danger"
                                    variant="ghost"
                                    size="sm"
                                    onClick={() =>
                                      removeTourFromOrdered(tour.value)
                                    }
                                  >
                                    <CIcon icon={cilTrash} />
                                  </CButton>
                                </CTooltip>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              ) : (
                <div className="text-center p-5 bg-light rounded">
                  <p className="text-muted mb-0">Nessun tour selezionato</p>
                  <p className="text-muted">
                    Seleziona i tour dalla sezione apposita
                  </p>
                </div>
              )}

              {orderedTours.length >= MAX_TOURS && (
                <div className="mt-3 text-warning">
                  <strong>Attenzione:</strong> Hai raggiunto il limite massimo
                  di {MAX_TOURS} tour sponsorizzati.
                </div>
              )}
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
  );
}

export default SponsoredToursList;
