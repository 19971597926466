/* eslint-disable */
import React, { createContext, useContext, useReducer, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { SUPPORTED_LANGUAGES } from 'src/utils/translationUtils';

// Definizione delle azioni per il reducer
const ACTIONS = {
  SET_INITIAL_PACKAGES: 'SET_INITIAL_PACKAGES',
  ADD_PACKAGE: 'ADD_PACKAGE',
  UPDATE_PACKAGE: 'UPDATE_PACKAGE',
  REMOVE_PACKAGE: 'REMOVE_PACKAGE',
  UPDATE_PACKAGE_TRANSLATION: 'UPDATE_PACKAGE_TRANSLATION',
  RESET_PACKAGES: 'RESET_PACKAGES',
};

// Stato iniziale del context
const initialState = {
  packages: [],
  isDirty: false,
  pendingTranslations: null,
};

// Creazione del context
const PackageContext = createContext(initialState);

// Reducer per gestire le modifiche allo stato
function packageReducer(state, action) {
  switch (action.type) {
    case ACTIONS.SET_INITIAL_PACKAGES:
      return {
        ...state,
        packages: action.payload,
        isDirty: false,
      };
    
    case ACTIONS.ADD_PACKAGE: {
      const newPackages = [...state.packages, action.payload];
      return {
        ...state,
        packages: newPackages,
        isDirty: true,
      };
    }
    
    case ACTIONS.UPDATE_PACKAGE: {
      const { index, packageData } = action.payload;
      const newPackages = [...state.packages];
      newPackages[index] = packageData;
      return {
        ...state,
        packages: newPackages,
        isDirty: true,
      };
    }
    
    case ACTIONS.REMOVE_PACKAGE: {
      const index = action.payload;
      const newPackages = [...state.packages];
      newPackages.splice(index, 1);
      return {
        ...state,
        packages: newPackages,
        isDirty: true,
      };
    }
    
    case ACTIONS.UPDATE_PACKAGE_TRANSLATION: {
      const { index, language, translations } = action.payload;
      const newPackages = [...state.packages];
      
      if (newPackages[index]) {
        // Assicuriamoci che la struttura translations esista
        if (!newPackages[index].translations) {
          newPackages[index].translations = {};
        }
        
        // Aggiorniamo le traduzioni per la lingua specificata
        newPackages[index].translations[language] = {
          ...newPackages[index].translations[language],
          ...translations,
        };
      }
      
      return {
        ...state,
        packages: newPackages,
        isDirty: true,
      };
    }
    
    case ACTIONS.RESET_PACKAGES:
      return {
        ...initialState,
      };
    
    default:
      return state;
  }
}

// Provider del context
export function PackageProvider({ children }) {
  const [state, dispatch] = useReducer(packageReducer, initialState);
  
  // Memorizzazione dei valori calcolati
  const basePrice = useMemo(() => {
    if (state.packages.length <= 0) return 0;
    
    if (state.packages.length === 1) {
      return parseFloat(state.packages[0].price || 0);
    }
    
    // Trova il pacchetto con il prezzo più basso
    const lowerPricePackage = state.packages.reduce((prev, curr) => {
      const prevPrice = parseFloat(prev.price || 0);
      const currPrice = parseFloat(curr.price || 0);
      return prevPrice <= currPrice ? prev : curr;
    });
    
    return parseFloat(lowerPricePackage.price || 0);
  }, [state.packages]);
  
  // Funzioni per manipolare i pacchetti
  const setInitialPackages = useCallback((packages) => {
    // Assicuriamoci che ogni pacchetto abbia una struttura di traduzioni completa
    const normalizedPackages = packages.map(pkg => {
      const normalizedPkg = { ...pkg };
      
      // Inizializza le traduzioni per tutte le lingue supportate
      if (!normalizedPkg.translations) {
        normalizedPkg.translations = {};
      }
      
      SUPPORTED_LANGUAGES.forEach(lang => {
        if (!normalizedPkg.translations[lang]) {
          normalizedPkg.translations[lang] = {
            package_title: '',
            package_description: '',
            included_services: [],
            warnings: [],
          };
        } else {
          // Assicurati che tutti i campi esistano
          normalizedPkg.translations[lang] = {
            package_title: normalizedPkg.translations[lang].package_title || '',
            package_description: normalizedPkg.translations[lang].package_description || '',
            included_services: normalizedPkg.translations[lang].included_services || [],
            warnings: normalizedPkg.translations[lang].warnings || [],
          };
        }
      });
      
      return normalizedPkg;
    });
    
    dispatch({
      type: ACTIONS.SET_INITIAL_PACKAGES,
      payload: normalizedPackages,
    });
  }, []);
  
  const addPackage = useCallback((packageData) => {
    // Assicuriamoci che il nuovo pacchetto abbia una struttura di traduzioni completa
    const normalizedPackage = { ...packageData };
    
    if (!normalizedPackage.translations) {
      normalizedPackage.translations = {};
    }
    
    SUPPORTED_LANGUAGES.forEach(lang => {
      if (!normalizedPackage.translations[lang]) {
        normalizedPackage.translations[lang] = {
          package_title: '',
          package_description: '',
          included_services: [],
          warnings: [],
        };
      }
    });
    
    dispatch({
      type: ACTIONS.ADD_PACKAGE,
      payload: normalizedPackage,
    });
  }, []);
  
  const updatePackage = useCallback((index, packageData) => {
    dispatch({
      type: ACTIONS.UPDATE_PACKAGE,
      payload: { index, packageData },
    });
  }, []);
  
  const removePackage = useCallback((index) => {
    dispatch({
      type: ACTIONS.REMOVE_PACKAGE,
      payload: index,
    });
  }, []);
  
  const updatePackageTranslation = useCallback((index, language, translations) => {
    dispatch({
      type: ACTIONS.UPDATE_PACKAGE_TRANSLATION,
      payload: { index, language, translations },
    });
  }, []);
  
  const resetPackages = useCallback(() => {
    dispatch({ type: ACTIONS.RESET_PACKAGES });
  }, []);
  
  // Funzioni getter
  const getPackages = useCallback(() => {
    return state.packages;
  }, [state.packages]);
  
  const getPackageByIndex = useCallback((index) => {
    return index >= 0 && index < state.packages.length ? state.packages[index] : null;
  }, [state.packages]);
  
  // Prepara i dati per il salvataggio
  const getPackagesForSave = useCallback(() => {
    return state.packages.map(pkg => {
      // Rimuoviamo eventuali dati temporanei o non necessari per il salvataggio
      const { __temp, ...cleanPackage } = pkg;
      return cleanPackage;
    });
  }, [state.packages]);
  
  // Esportiamo tutte le funzioni e lo stato attraverso il context
  const contextValue = useMemo(() => ({
    packages: state.packages,
    isDirty: state.isDirty,
    basePrice,
    
    // Setters
    setInitialPackages,
    addPackage,
    updatePackage,
    removePackage,
    updatePackageTranslation,
    resetPackages,
    
    // Getters
    getPackages,
    getPackageByIndex,
    getPackagesForSave,
  }), [
    state.packages,
    state.isDirty,
    basePrice,
    setInitialPackages,
    addPackage,
    updatePackage,
    removePackage,
    updatePackageTranslation,
    resetPackages,
    getPackages,
    getPackageByIndex,
    getPackagesForSave,
  ]);
  
  return (
    <PackageContext.Provider value={contextValue}>
      {children}
    </PackageContext.Provider>
  );
}

PackageProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Hook personalizzato per utilizzare il context
export function usePackages() {
  const context = useContext(PackageContext);
  
  if (!context) {
    throw new Error('usePackages deve essere utilizzato all\'interno di un PackageProvider');
  }
  
  return context;
}

export default PackageContext; 