/* eslint-disable */
// React
import React, { useEffect, useState, useCallback, useRef } from "react";

// React Redux
import { useSelector } from "react-redux";

// React Router
import { useParams } from "react-router-dom";

// React Hook Form
import { useForm, Controller } from "react-hook-form";

// CoreUI Components
import {
  CButton,
  CCol,
  CForm,
  CFormInput,
  CFormSelect,
  CFormTextarea,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CRow,
} from "@coreui/react";

// Contexts
import { PackageProvider, usePackages } from 'src/contexts/PackageContext';

// Hooks
import useTranslationBatch from 'src/hooks/useTranslationBatch';

// Utils
import toast from "react-hot-toast";
import {
  LANGUAGE_OPTIONS,
  SUPPORTED_LANGUAGES,
  DEFAULT_META_DATA,
} from "src/utils/translationUtils";

// Services
import TourService from "src/services/api/TourService";
import VercelService from "src/services/VercelService";

// Components
import AppDetail from "src/components/ui/Detail/AppDetail";
import AppLoadingSpinner from "src/components/ui/AppLoadingSpinner";
import AppMultiData from "src/components/ui/MultiData/AppMultiData";
import DateRangePicker from "src/components/DateRangePicker/DateRangePicker";
import ExtraClosingTimes from "src/components/ui/ExtraClosingTimes/ExtraClosingTimes";
import Gallery from "src/components/ui/Images/Gallery/Gallery";
import ImageWithPreview from "src/components/ui/Images/ImageWithPreview";
import LanguagesCheckbox from "src/components/ui/LanguagesCheckbox/LanguagesCheckbox";
import PackageForm from "./Packages/PackageForm";
import ServicesCheckbox from "src/components/ui/ServicesCheckbox/ServicesCheckbox";
import TimesCheckbox from "src/components/ui/TimesCheckbox/TimesCheckbox";
import TimeTable from "src/components/ui/TimeTable/TimeTable";
import TranslationBatchModal from 'src/components/ui/TranslationBatchModal';
import TranslationStatus from "src/components/ui/TranslationStatus/TranslationStatus";

// Componente principale che avvolge tutto con il PackageProvider
function ToursDetailWrapper() {
  return (
    <PackageProvider>
      <ToursDetailContent />
    </PackageProvider>
  );
}

// Componente interno che utilizza il PackageContext
function ToursDetailContent() {
  const { id } = useParams();
  const userGroup = useSelector((state) => state.user.value.user_group);
  const [state, setState] = useState({
    loading: true,
    error: null,
    currentActiveTab: "package-tab",
  });
  const [currentLanguage, setCurrentLanguage] = useState("it");
  const [notificationsModal, setNotificationsModal] = useState({
    show: false,
    text: "",
    tabTarget: "package-tab",
  });
  const { control, reset, getValues, setValue, watch } = useForm({
    defaultValues: {
      name: "",
      meta_title: "",
      meta_keywords: "",
      meta_description: "",
      base_price: 0,
      abstract: "",
      description: "",
      contact_name: "",
      category_name: "",
      url_friendly_name: "",
      tour_warnings: "",
      attributes: {
        purchase_options: [],
        TimeTable: {},
        coordinates: {},
        languages: [],
        tasting_kind: [],
        tastingTimes: [],
        closingDays: [],
        extraClosing: [],
      },
      media_contents: [],
      tags: [],
    },
  });
  const watchTranslations = watch("translations");
  const [tourPreviewImage, setTourPreviewImage] = useState(null);
  const [tourMediaContents, setTourMediaContents] = useState([]);

  // Utilizziamo le funzioni e lo stato dal PackageContext
  const { 
    packages, 
    basePrice, 
    setInitialPackages, 
    addPackage, 
    updatePackage, 
    removePackage, 
    updatePackageTranslation,
    getPackagesForSave
  } = usePackages();

  const vercelService = new VercelService();

  const initialModelRef = useRef(null);

  const {
    translationModal,
    selectedLanguages,
    setSelectedLanguages,
    handleTranslate,
    executeTranslation,
    closeTranslationModal,
    resetSessionTranslations,
  } = useTranslationBatch({
    type: 'products',
    originalModel: initialModelRef.current,
    batchSize: 3,
    onTranslationComplete: (translations) => {
      const currentFormValues = getValues();
      const updatedTranslations = {
        ...currentFormValues.translations,
        ...translations,
      };

      setValue('translations', updatedTranslations);
      toast.success('Traduzioni completate con successo');
    },
  });

  const handlePackageTranslate = (packageData) => {
    if (currentLanguage !== "it") {
      toast.error(
        "Le traduzioni automatiche sono disponibili solo per l'italiano"
      );
      return;
    }

    // Recuperiamo il form del pacchetto per ottenere i valori aggiornati
    const formElement = document.getElementById(`edit_pacchetto`);
    if (!formElement) {
      console.error("Form element not found");
      return;
    }

    const formData = new FormData(formElement);
    const currentFormData = Object.fromEntries(formData.entries());

    // Otteniamo i valori correnti in italiano, inclusi i servizi e le avvertenze dal form
    const currentItalianValues = {
      package_title: currentFormData[`translations.it.package_title`] || "",
      package_description:
        currentFormData[`translations.it.package_description`] || "",
      included_services: JSON.parse(
        currentFormData[`translations.it.included_services`] || "[]"
      ),
      warnings: JSON.parse(currentFormData[`translations.it.warnings`] || "[]"),
    };

    // Inizializziamo i callback per la gestione del completamento delle traduzioni
    const onPackageTranslationComplete = (translations) => {
      try {
        // Per ogni lingua tradotta, aggiorniamo il pacchetto nel Context
        Object.keys(translations).forEach(lang => {
          if (translations[lang] && lang !== 'it') {
            updatePackageTranslation(packageData.idx, lang, translations[lang]);
          }
        });
        
        toast.success("Traduzioni del pacchetto completate con successo");
      } catch (error) {
        console.error('Error updating package translations:', error);
        toast.error('Errore durante l\'aggiornamento delle traduzioni del pacchetto');
      }
    };

    // Prepariamo i campi da tradurre
    const fieldsToTranslate = {
      ...currentItalianValues,
      onTranslationComplete: onPackageTranslationComplete
    };

    // Aggiungiamo i campi completi per tutte le lingue esistenti per preservarle durante la traduzione
    const packageFullTranslations = {};
    
    // Otteniamo le traduzioni attuali dal contesto
    const currentPackage = packages[packageData.idx];
    
    // Per ogni lingua creiamo una struttura completa con campi vuoti o esistenti
    SUPPORTED_LANGUAGES.forEach(lang => {
      if (lang !== 'it') { // Escludiamo l'italiano che sarà usato come fonte
        packageFullTranslations[lang] = {
          package_title: currentPackage?.translations?.[lang]?.package_title || "",
          package_description: currentPackage?.translations?.[lang]?.package_description || "",
          included_services: currentPackage?.translations?.[lang]?.included_services || [],
          warnings: currentPackage?.translations?.[lang]?.warnings || []
        };
      }
    });

    // Usiamo il metodo del hook per aprire la modale di traduzione
    handleTranslate({
      ...fieldsToTranslate,
      _preserveExistingPackageTranslations: packageFullTranslations
    });
  };

  const updateTranslationState = useCallback(
    (field) => (value) => {
      setState((prev) => ({
        ...prev,
        model: {
          ...prev.model,
          translations: {
            ...prev.model.translations,
            [currentLanguage]: {
              ...prev.model.translations[currentLanguage],
              [field]: value,
            },
          },
        },
      }));
    },
    [currentLanguage]
  );

  const formatModel = (response) => {
    const tourResponseData = { ...(response?.data || {}) };
    const tourModelData = {};

    // Dati base
    tourModelData.product_category_id = tourResponseData.product_category_id;
    tourModelData.contact_id = tourResponseData.contact_id;
    tourModelData.base_price = tourResponseData.base_price;
    tourModelData.url_friendly_name = tourResponseData.url_friendly_name;
    tourModelData.contact_name = tourResponseData?.contact?.business_name;
    tourModelData.category_name = tourResponseData?.product_category?.name;
    tourModelData.published = tourResponseData?.published || false;

    // Traduzioni
    tourModelData.translations = {};

    SUPPORTED_LANGUAGES.forEach((lang) => {
      if (
        tourResponseData.translations &&
        tourResponseData.translations[lang]
      ) {
        tourModelData.translations[lang] = {
          name: tourResponseData.translations[lang].name || "",
          abstract: tourResponseData.translations[lang].abstract || "",
          description: tourResponseData.translations[lang].description || "",
          meta_title: tourResponseData.translations[lang].meta_title || "",
          meta_keywords:
            tourResponseData.translations[lang].meta_keywords || "",
          meta_description:
            tourResponseData.translations[lang].meta_description || "",
          tour_warnings:
            tourResponseData.translations[lang].tour_warnings || "",
        };
      } else {
        // Inizializza con valori vuoti se non esistono traduzioni
        tourModelData.translations[lang] = {
          name: "",
          abstract: "",
          description: "",
          meta_title: "",
          meta_keywords: "",
          meta_description: "",
          tour_warnings: "",
        };
      }
    });

    // Attributi
    tourModelData.attributes = tourResponseData?.attributes || {
      purchase_options: [],
      timeTable: {},
      languages: [],
      closingDays: [],
      coordinates: {},
      extraClosing: [],
    };

    // Inizializziamo i pacchetti nel context
    if (tourModelData.attributes.purchase_options) {
      // Normalizza i dati dei pacchetti prima di inserirli nel contesto
      const normalizedPackages = tourModelData.attributes.purchase_options.map((option) => ({
        ...option,
        translations: option.translations || {
          it: {
            package_title: option.package_title || "",
            package_description: option.package_description || "",
            included_services: option.included_services || [],
            warnings: option.warnings || [],
          },
        },
      }));
      
      // Imposta i pacchetti nel contesto
      setInitialPackages(normalizedPackages);
    }

    // Media
    tourModelData.media_contents = tourResponseData?.media_contents || [];
    tourModelData.tags = tourResponseData?.tags || [];

    return tourModelData;
  };

  useEffect(() => {
    if (id !== null && typeof id !== "undefined") {
      const tourService = new TourService();
      const okGetDetails = (response) => {
        const tourData = formatModel(response);

        // Imposta initialModelRef una sola volta
        if (!initialModelRef.current) {
          initialModelRef.current = tourData;
        }

        // Reset del form con i valori tradotti corretti
        const formValues = {
          ...tourData,
          translations: tourData.translations,
          attributes: {
            ...tourData.attributes,
            // Utilizziamo i pacchetti dal contesto
            purchase_options: packages,
          },
        };

        reset(formValues);
        setState({ ...state, loading: false, model: tourData });

        if (
          Array.isArray(tourData.media_contents) &&
          tourData.media_contents.length > 0
        ) {
          setTourMediaContents([
            ...tourData.media_contents
              .filter((current) => current.type === "tour_image")
              .sort((a, b) => a.order - b.order),
          ]);
          setTourPreviewImage(
            tourData.media_contents.find(
              (current) => current.type === "tour_preview_image"
            )
          );
        }
      };

      const koGetDetails = (error) => {
        console.error("Error loading tour:", error);
        const errorMessage = error?.data?.message || "Nessun errore";
        setState({ ...state, loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      tourService.getItem(id, okGetDetails, koGetDetails);
    }
  }, [id]);

  // Aggiorniamo il prezzo base quando cambia nel context
  useEffect(() => {
    setValue("base_price", basePrice);
  }, [basePrice, setValue]);

  const previewPage = (urlPage) => {
    console.log(`Navigate to ${urlPage}`);
  };

  const saveAction = (type) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        const tourData = formatModel(response);

        // Per aggiornare il riferimento iniziale
        initialModelRef.current = tourData;

        reset(tourData);
        if (
          Array.isArray(tourData.media_contents) &&
          tourData.media_contents.length > 0
        ) {
          setTourMediaContents([
            ...tourData.media_contents
              .filter((current) => current.type === "tour_image")
              .sort((a, b) => a.order - b.order),
          ]);
          setTourPreviewImage(
            tourData.media_contents.find(
              (current) => current.type === "tour_preview_image"
            )
          );
        }
        setState({ ...state, loading: false, model: tourData });
        
        // Reset delle traduzioni in memoria dopo il salvataggio riuscito
        resetSessionTranslations();
        
        resolve();
      };

      const koEditCallback = (response) => {
        setState({
          loading: false,
          error: response?.error,
          errorToShow: response?.error,
        });
        reject(new Error(response.data.message));
      };

      const prepareToUpdate = () => {
        const newData = getValues();
        delete newData.category_name;

        // Utilizziamo i pacchetti dal context per l'aggiornamento
        newData.attributes.purchase_options = getPackagesForSave();

        // Gestiamo le traduzioni del tour
        if (!newData.translations) {
          newData.translations = {};
        }

        // Manteniamo le traduzioni esistenti del tour combinandole con eventuali aggiornamenti
        newData.translations = {
          ...state.model?.translations,
          ...newData.translations,
        };

        // Aggiorniamo i campi principali con i valori italiani
        newData.abstract = newData.translations?.it?.abstract || "";
        newData.description = newData.translations?.it?.description || "";
        newData.name = newData.translations?.it?.name || "";
        newData.meta_title = newData.translations?.it?.meta_title || "";
        newData.meta_keywords = newData.translations?.it?.meta_keywords || "";
        newData.meta_description =
          newData.translations?.it?.meta_description || "";
        newData.tour_warnings = newData.translations?.it?.tour_warnings || "";
        newData.media_contents = tourMediaContents;

        return newData;
      };

      const tourService = new TourService();
      let formData = null;
      switch (type) {
        case "publish":
          if (tourMediaContents.length >= 7) {
            formData = prepareToUpdate();
            const formDataToPublish = { ...formData };
            formDataToPublish.published = !formData.published;
            tourService.updateItem(
              id,
              formDataToPublish,
              okEditCallback,
              koEditCallback
            );
          } else {
            setNotificationsModal({
              text: "Per poter pubblicare il tour, si devono caricare 7 immagini della galleria Tour.",
              show: true,
              tabTarget: "gallery-tab",
            });
            reject();
          }
          break;
        case "publishNow":
          if (tourMediaContents.length >= 7) {
            vercelService
              .deploy()
              .then(() => {
                resolve({ message: "Pubblicazione avvenuta con successo!" });
              })
              .catch((err) => {
                reject(new Error(err.data.message));
              });
          } else {
            setNotificationsModal({
              text: "Per poter pubblicare il tour, si devono caricare 7 immagini della galleria Tour.",
              show: true,
              tabTarget: "gallery-tab",
            });
            reject();
          }
          break;
        case "save":
          formData = prepareToUpdate();
          tourService.updateItem(id, formData, okEditCallback, koEditCallback);
          break;
        default:
          formData = prepareToUpdate();
          tourService.updateItem(id, formData, okEditCallback, koEditCallback);
      }
    });

    toast.promise(
      savePromise,
      {
        loading: "Attendere, operazione in corso...",
        success: (data) => data?.message || "Operazione eseguita con successo!",
        error: (err) =>
          err ? err.toString() : "Ops, si è verificato un errore!",
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      }
    );
  };

  const insertPackage = (data, formProps) => {
    // Utilizziamo la funzione del context per aggiungere un pacchetto
    addPackage(data);
    formProps.closeModal();
  };

  const editPackage = (data, formProps) => {
    if (typeof formProps.target.data.idx === "number") {
      // Utilizziamo la funzione del context per aggiornare un pacchetto
      updatePackage(formProps.target.data.idx, data);
    }
    formProps.closeModal();
  };

  const deletePackage = (data) => {
    if (typeof data.idx === "number" && data.idx >= 0) {
      // Utilizziamo la funzione del context per rimuovere un pacchetto
      removePackage(data.idx);
    }
  };

  const updateTimeTable = (timeTableData) => {
    const newModel = { ...getValues() };
    newModel.attributes.timeTable = { ...timeTableData };
    setState({ ...state, model: newModel });
  };

  const updateToursSelections = (field, selectionData) => {
    const newModel = { ...getValues() };
    newModel.attributes[field] = [...selectionData];
    setState({ ...state, model: newModel });
  };

  const updateTourLanguages = (tourLanguagesData) => {
    const newModel = { ...getValues() };
    newModel.attributes.languages = tourLanguagesData;
    setState({ ...state, model: newModel });
  };

  const updateTourTastingTime = (tourTastingTimesData) => {
    const newModel = { ...getValues() };
    newModel.attributes.tastingTimes = [...tourTastingTimesData];
    setState({ ...state, model: newModel });
  };

  const updateClosingDays = (closingDaysData) => {
    const newModel = { ...getValues() };
    newModel.attributes.closingDays = [...closingDaysData];
    setState({ ...state, model: newModel });
  };

  const updateExtraClosingTimes = (value) => {
    const newModel = { ...getValues() };
    newModel.attributes.extraClosing = value;
    setState({ ...state, model: newModel });
  };

  const handleChangePreviewImage = (response) => {
    if (response.job === "delete") return setTourPreviewImage(null);
    return setTourPreviewImage(response.response.data);
  };

  const changeCurrentTab = (tabIndex) => {
    if (tabIndex !== state.currentActiveTab)
      setState({ ...state, currentActiveTab: tabIndex });
  };

  const onCloseNotificationsModal = () => {
    setNotificationsModal({
      ...notificationsModal,
      show: false,
    });
    setState({ ...state, currentActiveTab: notificationsModal.tabTarget });
  };

  const onChangeCoordinateValue = (e, field) => {
    // Allow only numeric values, comma and dot
    const sanitizedValue = e.target.value.replace(/[^0-9,.]/g, "");
    field.onChange(sanitizedValue);
  };

  const renderTranslationModal = () => (
    <TranslationBatchModal
      show={translationModal.show}
      onClose={closeTranslationModal}
      selectedLanguages={selectedLanguages}
      onLanguageSelect={setSelectedLanguages}
      onStartTranslation={executeTranslation}
      batchProgress={translationModal.progress}
      currentBatch={translationModal.currentBatch}
      totalBatches={translationModal.totalBatches}
      translationStatus={translationModal.translationStatus}
      isTranslating={translationModal.isTranslating}
      currentBatchLanguages={translationModal.currentBatchLanguages}
    />
  );

  const renderNotificationsModal = () => (
    <CModal backdrop="static" visible={notificationsModal.show}>
      <CModalHeader closeButton={false}>
        <CModalTitle>Avviso</CModalTitle>
      </CModalHeader>
      <CModalBody>{notificationsModal.text}</CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => onCloseNotificationsModal()}>
          Chiudi
        </CButton>
      </CModalFooter>
    </CModal>
  );

  if (state.loading === true) return <AppLoadingSpinner />;

  if (state.error) return <p>NO DATA</p>;

  return (
    <>
      <CRow className="mb-4">
        <CCol xs={12} md={6} lg={4}>
          <CFormSelect
            value={currentLanguage}
            onChange={(e) => setCurrentLanguage(e.target.value)}
            options={LANGUAGE_OPTIONS}
            label="Lingua"
          />
        </CCol>
      </CRow>

      <CForm>
        <div>
          <AppDetail
            sectionId="products"
            previewPage={previewPage}
            isContentPublished={state?.model?.published}
            saveAction={saveAction}
            name={getValues("name")}
            urlFriendlyName={getValues("url_friendly_name")}
            currentActiveTab={state.currentActiveTab}
            onTabChange={changeCurrentTab}
            tabsHeaders={[
              {
                index: "package-tab",
                label: "PACCHETTI TOUR",
              },
              {
                index: "datetime-tab",
                label: "ORARI E GIORNI",
              },
              {
                index: "main-tab",
                label: "NOME E DESCRIZIONE TOUR",
              },
              {
                index: "coordinate-tab",
                label: "COORDINATE TOUR",
              },
              {
                index: "cover-tab",
                label: "FOTO COPERTINA",
              },
              {
                index: "gallery-tab",
                label: "FOTO GALLERIA",
              },
              {
                index: "tasting-tab",
                label: "DEGUSTAZIONI",
              },
              {
                index: "language-tab",
                label: "LINGUA TOUR",
              },
              {
                index: "web-tab",
                label: "META DATI SEO",
              },
            ]}
            tabsContents={[
              {
                index: "package-tab",
                content: (
                  <CRow>
                    <CCol md={12}>
                      <h4>I tuoi Pacchetti Esperienza</h4>
                      <p>
                        Puoi creare un massimo di 3 pacchetti che saranno
                        visibili nella tua pagina di presentazione del tour,
                        facendo clic sull&quot;icona matita&quot; puoi
                        modificare il dettaglio dei servizi offerti per ogni
                        pacchetto, facendo click sul &quot;cestino&quot; potrai
                        cancellare il pacchetto.
                      </p>
                      <hr className="mb-4" />
                      <AppMultiData
                        buttonAddString="Aggiungi Nuovo Pacchetto"
                        title="Pacchetti"
                        item="Pacchetto"
                        modalSize="xl"
                        formId="pacchetto"
                        userGroup={userGroup}
                        currentLanguage={currentLanguage}
                        onLanguageChange={setCurrentLanguage}
                        showTranslateButton={true}
                        createFormComponent={(formProps) =>
                          PackageForm({
                            formId: "create_pacchetto",
                            submit: (data) => insertPackage(data, formProps),
                            parentProps: {
                              show: formProps.show,
                            },
                            userGroup,
                            currentLanguage,
                          })
                        }
                        editFormComponent={(formProps) => {
                          return PackageForm({
                            formId: "edit_pacchetto",
                            submit: (data) => editPackage(data, formProps),
                            parentProps: {
                              show: formProps.show,
                              target: formProps.target,
                            },
                            userGroup,
                            currentLanguage,
                          });
                        }}
                        deleteFunction={(deleteData) =>
                          deletePackage(deleteData)
                        }
                        columns={[
                          { index: "name_tag", type: "select" },
                          { index: "price", type: "currency" },
                          { index: "price_type_tag", type: "select" },
                        ]}
                        data={packages}
                        maxData={3}
                        onTranslate={handlePackageTranslate}
                      />
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "cover-tab",
                content: (
                  <CRow>
                    <CCol>
                      <ImageWithPreview
                        contentId={id}
                        userGroup={userGroup}
                        contentType="tour_preview_image"
                        Service={TourService}
                        title="Copertina del tour"
                        data={tourPreviewImage}
                        onUpdate={(data) => handleChangePreviewImage(data)}
                      />
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "gallery-tab",
                content: (
                  <CRow>
                    <CCol>
                      <Gallery
                        contentId={id}
                        contentType="tour_image"
                        Service={TourService}
                        userGroup={userGroup}
                        title="Galleria del tour"
                        data={tourMediaContents}
                        onUpdate={(imagesArray) =>
                          setTourMediaContents(imagesArray)
                        }
                        changeTitle
                      />
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "web-tab",
                content: (
                  <CRow className="g-3">
                    <CCol>
                      <h4>Inserisci i Meta Dati SEO</h4>
                      <p>
                        Inserisci il Titolo, Keywords e la Meta Descrizione.
                        <div className="alert alert-warning mt-2">
                          Se non vengono forniti metadati, verranno utilizzati
                          sul sito web quelli predefiniti indicati nei
                          placeholder.
                        </div>
                      </p>
                      <hr className="mb-4" />
                      <div className="d-flex justify-content-start align-items-center mb-4 gap-5">
                        {currentLanguage === "it" && (
                          <CButton
                            color="primary"
                            size="sm"
                            onClick={() =>
                              handleTranslate({
                                meta_title: getValues(
                                  `translations.it.meta_title`
                                ),
                                meta_keywords: getValues(
                                  `translations.it.meta_keywords`
                                ),
                                meta_description: getValues(
                                  `translations.it.meta_description`
                                ),
                              })
                            }
                          >
                            Traduzione automatica
                          </CButton>
                        )}
                        <TranslationStatus
                          translations={watchTranslations}
                          fields={[
                            "meta_title",
                            "meta_keywords",
                            "meta_description",
                          ]}
                        />
                      </div>
                      <CRow className="g-3 mb-4">
                        <CCol md={12}>
                          <Controller
                            name={`translations.${currentLanguage}.meta_title`}
                            control={control}
                            render={({ field }) => {
                              const metaTitleValue =
                                watchTranslations?.[currentLanguage]
                                  ?.meta_title || "";
                              return (
                                <CFormInput
                                  label="Titolo"
                                  placeholder={
                                    DEFAULT_META_DATA?.meta_title?.[
                                      currentLanguage
                                    ] || "Inserisci un meta titolo"
                                  }
                                  id="tour-meta_title"
                                  value={metaTitleValue}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    updateTranslationState("meta_title")(
                                      e.target.value
                                    );
                                  }}
                                />
                              );
                            }}
                          />
                        </CCol>
                        <CCol md={12}>
                          <Controller
                            name={`translations.${currentLanguage}.meta_keywords`}
                            control={control}
                            render={({ field }) => {
                              const metaKeywordsValue =
                                watchTranslations?.[currentLanguage]
                                  ?.meta_keywords || "";
                              return (
                                <CFormInput
                                  label={
                                    <>
                                      Keywords
                                      <br />
                                      <small>
                                        Per inserire più Keyword aggiungere tra
                                        le varie parole una &quot;virgola&quot;
                                      </small>
                                    </>
                                  }
                                  placeholder="Inserisci le keywords separate da virgola"
                                  id="tour-meta_keywords"
                                  value={metaKeywordsValue}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    updateTranslationState("meta_keywords")(
                                      e.target.value
                                    );
                                  }}
                                />
                              );
                            }}
                          />
                        </CCol>
                        <CCol md={12}>
                          <Controller
                            name={`translations.${currentLanguage}.meta_description`}
                            control={control}
                            render={({ field }) => {
                              const metaDescriptionValue =
                                watchTranslations?.[currentLanguage]
                                  ?.meta_description || "";
                              return (
                                <>
                                  <CFormTextarea
                                    maxLength={160}
                                    label="Meta descrizione"
                                    placeholder={
                                      DEFAULT_META_DATA?.meta_description?.[
                                        currentLanguage
                                      ] || "Inserisci una meta descrizione"
                                    }
                                    id="tour-meta_description"
                                    rows="3"
                                    value={metaDescriptionValue}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      updateTranslationState(
                                        "meta_description"
                                      )(e.target.value);
                                    }}
                                  />
                                  <small>Non superare i 160 caratteri</small>
                                </>
                              );
                            }}
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "main-tab",
                content: (
                  <CRow className="g-3">
                    <CCol>
                      <h4>Contenuti testuali</h4>
                      <p>
                        Inserisci i testi per descrivere al meglio il tuo tour
                      </p>
                      <hr className="mb-4" />
                      <div className="d-flex justify-content-start align-items-center mb-4 gap-5">
                        {currentLanguage === "it" && (
                          <CButton
                            color="primary"
                            size="sm"
                            onClick={() =>
                              handleTranslate({
                                name: getValues(`translations.it.name`),
                                abstract: getValues(`translations.it.abstract`),
                                description: getValues(
                                  `translations.it.description`
                                ),
                                tour_warnings: getValues(
                                  `translations.it.tour_warnings`
                                ),
                              })
                            }
                          >
                            Traduzione automatica
                          </CButton>
                        )}
                        <TranslationStatus
                          translations={watchTranslations}
                          fields={["abstract", "description", "tour_warnings"]}
                        />
                      </div>
                      <CRow className="g-3 mb-4">
                        {currentLanguage === "it" && (
                          <CCol md={12}>
                            <Controller
                              name={`translations.${currentLanguage}.name`}
                              control={control}
                              render={({ field }) => (
                                <CFormTextarea
                                  maxLength={165}
                                  label={
                                    <>
                                      <span className="fs-5">Nome tour</span>
                                      <br />
                                      <small>
                                        Qui puoi modificare il nome del tour
                                      </small>
                                    </>
                                  }
                                  id="tour-name"
                                  rows="3"
                                  value={
                                    field.value ||
                                    state?.model?.translations?.[
                                      currentLanguage
                                    ]?.name ||
                                    ""
                                  }
                                  onChange={field.onChange}
                                />
                              )}
                            />
                          </CCol>
                        )}
                        <CCol md={12}>
                          <Controller
                            name={`translations.${currentLanguage}.abstract`}
                            control={control}
                            render={({ field }) => {
                              const abstractValue =
                                watchTranslations?.[currentLanguage]
                                  ?.abstract || "";

                              return (
                                <>
                                  <CFormTextarea
                                    maxLength={165}
                                    label={
                                      <>
                                        <span className="fs-5">
                                          Descrizione breve del tour
                                        </span>
                                      </>
                                    }
                                    id="tour-abstract"
                                    rows="3"
                                    value={abstractValue}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      updateTranslationState("abstract")(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <p className="text-align-end">
                                    <small>Max. 165 caratteri</small>
                                  </p>
                                </>
                              );
                            }}
                          />
                        </CCol>
                        <CCol md={12}>
                          <Controller
                            name={`translations.${currentLanguage}.description`}
                            control={control}
                            render={({ field }) => {
                              const descriptionValue =
                                watchTranslations?.[currentLanguage]
                                  ?.description || "";

                              return (
                                <>
                                  <CFormTextarea
                                    maxLength={1150}
                                    label={
                                      <span className="fs-5">
                                        Descrizione completa del tour
                                      </span>
                                    }
                                    id="tour-description"
                                    rows="5"
                                    value={descriptionValue}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      updateTranslationState("description")(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <p className="text-align-end">
                                    <small>Max. 1150 caratteri</small>
                                  </p>
                                </>
                              );
                            }}
                          />
                        </CCol>
                        <CCol md={12}>
                          <Controller
                            name={`translations.${currentLanguage}.tour_warnings`}
                            control={control}
                            render={({ field }) => {
                              // Otteniamo il valore corretto dando priorità alle traduzioni più recenti
                              const warningValue =
                                watchTranslations?.[currentLanguage]
                                  ?.tour_warnings || "";

                              return (
                                <>
                                  <CFormTextarea
                                    maxLength={180}
                                    label={
                                      <>
                                        <span className="fs-5">Avvertenze</span>
                                        <br />
                                        <small>
                                          Eventuali informazioni aggiuntive che
                                          vuoi comunicare ai visitatori della
                                          tua scheda presentazione del tour
                                        </small>
                                      </>
                                    }
                                    id="tour-warnings"
                                    rows="5"
                                    value={warningValue}
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                      updateTranslationState("tour_warnings")(
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <p className="text-align-end">
                                    <small>Max. 180 caratteri</small>
                                  </p>
                                </>
                              );
                            }}
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "coordinate-tab",
                content: (
                  <CRow className="g-3">
                    <CCol>
                      <h4>Coordinate del Tour</h4>
                      <p>
                        Qui puoi inserire o modificare le coordinate del tuo
                        tour in modo che sia visibile sulla mappa del sito
                        Vinbacco.
                        <br />
                        Inserisci le coordinate in formato decimale,
                        esempio:&nbsp;
                        <b>45,123456</b>
                      </p>
                      <hr className="mb-4" />
                      <CRow className="g-3 mb-4">
                        <CCol md={6}>
                          <Controller
                            name="attributes.coords.latitude"
                            control={control}
                            render={({ field }) => (
                              <CFormInput
                                label="Latitudine"
                                placeholder="Inserisci la latitudine"
                                id="tour-latitude"
                                {...field}
                                onChange={(e) =>
                                  onChangeCoordinateValue(e, field)
                                }
                              />
                            )}
                          />
                        </CCol>
                        <CCol md={6}>
                          <Controller
                            name="attributes.coords.longitude"
                            control={control}
                            render={({ field }) => (
                              <CFormInput
                                label="Longitudine"
                                placeholder="Inserisci la longitudine"
                                id="tour-longitude"
                                {...field}
                                onChange={(e) =>
                                  onChangeCoordinateValue(e, field)
                                }
                              />
                            )}
                          />
                        </CCol>
                      </CRow>
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "language-tab",
                content: (
                  <CRow className="g-3">
                    <CCol>
                      <h4>Lingue del Tour</h4>
                      <p>
                        Seleziona le lingue in cui puoi offrire il tour di
                        degustazione e visita in cantina.
                      </p>
                      <hr className="mb-4" />
                      <LanguagesCheckbox
                        data={state?.model?.attributes?.languages}
                        onChange={(value) => updateTourLanguages(value)}
                      />
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "datetime-tab",
                content: (
                  <CRow className="g-3">
                    <CCol>
                      <h4>Orari e Giorni del Tour</h4>
                      <p>
                        Seleziona gli orari in cui sei disponibile per offrire
                        il sevizio esperienza, ti ricordiamo che potrai
                        selezionare un massimo di 8 orari, facendo riferimento
                        ai giorni indicati.
                        <br />
                        Per segnalare un giorno di chiusura e mostrarlo nella
                        tua scheda di presentazione del tour, ti basta
                        selezionare la casella dove c&apos;è scritto
                        &quot;Chiuso&quot;.
                      </p>
                      <hr className="mb-4" />
                      <TimeTable
                        data={state?.model?.attributes?.timeTable}
                        onChange={(value) => updateTimeTable(value)}
                        max={8}
                        description="Seleziona un massimo di 8 orari in cui sei disponibile per effettuare le prenotazioni {day}"
                      />
                      <hr />
                      <TimesCheckbox
                        maxSelection={8}
                        title="Orari di degustazione"
                        description="Seleziona gli orari in cui offri il tuo servizio di degustazione"
                        data={state?.model?.attributes?.tastingTimes}
                        onChange={(value) => updateTourTastingTime(value)}
                      />
                      <hr />
                      <DateRangePicker
                        title="Giorni di chiusura"
                        description="Seleziona i giorni in cui sei chiuso"
                        data={state?.model?.attributes?.closingDays || []}
                        onChange={(value) => updateClosingDays(value)}
                      />
                      <hr />
                      <ExtraClosingTimes
                        data={state?.model?.attributes?.extraClosing || []}
                        onChange={updateExtraClosingTimes}
                        timeTable={state?.model?.attributes?.timeTable || {}}
                      />
                    </CCol>
                  </CRow>
                ),
              },
              {
                index: "tasting-tab",
                content: (
                  <CRow className="g-3">
                    <CCol>
                      <h4>Tipologia Degustazioni speciali</h4>
                      <p>
                        Seleziona eventuali degustazioni speciali che vuoi
                        offrire ai tuoi clienti.
                      </p>
                      <hr className="mb-4" />
                      <ServicesCheckbox
                        serviceType="tasting"
                        data={state?.model?.attributes?.tasting_kind}
                        onChange={(value) =>
                          updateToursSelections("tasting_kind", value)
                        }
                      />
                    </CCol>
                  </CRow>
                ),
              },
            ]}
          />
        </div>
      </CForm>
      {renderNotificationsModal()}
      {renderTranslationModal()}
    </>
  );
}

export default ToursDetailWrapper;
