/* eslint-disable */

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format, isSameDay } from "date-fns";
import { useForm, Controller } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { CForm, CRow, CCol, CFormInput, CButton, CBadge } from "@coreui/react";
import { formatItalianDateWithTime, getItalianZonedDate } from "src/utils/dateValidation";

// SERVICES
import OrdersService from "src/services/api/OrdersService";
import composeErrorFormType from "src/utils/composeErrorFormType";
import CitiesService from "src/services/api/CitiesService";
// COMPONENTS
import AppDetail from "src/components/ui/Detail/AppDetail";
import AppLoadingSpinner from "src/components/ui/AppLoadingSpinner";
import CIcon from "@coreui/icons-react";
import { cilCheckCircle, cilXCircle } from "@coreui/icons";
import utils from "src/services/api/utils/utils";

const OrdersDetail = () => {
  const { id } = useParams();
  const userGroup = useSelector((state) => state.user.value.user_group);
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  const [state, setState] = useState({
    loading: true,
    model: null,
    processingBooking: false,
    city: null,
  });

  const ordersService = new OrdersService();

  const loadDefaultCity = (defaultCityId) => {
    const newState = { ...state };
    const citiesService = new CitiesService();
    const okGetCities = (response) => {
      if (response.data?.city_name) {
        newState.city = response.data?.city_name;
        setValue("user_city", response.data?.city_name);
        newState.loading = false;
        setState(newState);
      }
    };
    const koGetCities = () => [];
    citiesService.getItem(defaultCityId, okGetCities, koGetCities);
  };

  const formatModel = (response) => {
    const responseData = { ...response.data };
    const invoiceData = responseData.invoice_data;
    setValue("user_first_name", invoiceData?.first_name || "");
    setValue("user_last_name", invoiceData?.last_name || "");
    setValue("user_tax_code", invoiceData?.tax_code || "");
    setValue("user_address", invoiceData?.address || "");
    setValue("user_city", invoiceData?.city || "");
    setValue("user_state", invoiceData?.state || "");
    setValue("user_zip_code", invoiceData?.zip_code || "");
    setValue("user_email", invoiceData.email || "-");
    setValue("user_phone", invoiceData.phone || "-");
    setValue("user_country", invoiceData.country || "-");
    if (invoiceData.country === "6377e30cbc90c6646037060b") {
      setValue("user_country", "Italia");
    }
    return responseData;
  };

  const onSubmit = (data) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error });
        reject();
      };

      ordersService.updateItem(state.model["_id"], data, okEditCallback, koEditCallback);
    });

    toast.promise(
      savePromise,
      {
        loading: "Attendere, salvando le modifiche...",
        success: "Dato modificato con successo!",
        error: "Ops, si è verificato un errore!",
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      }
    );
  };

  useEffect(() => {
    if (id !== null && typeof id !== "undefined") {
      const okGetCallback = (response) => {
        setState({ ...state, loading: false, model: formatModel(response) });
      };

      const koGetCallback = (error) => {
        const errorMessage = error?.data?.message || "Nessun errore";
        setState({ ...state, loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      ordersService.getItem(id, okGetCallback, koGetCallback);
    }
  }, [id]);

  useEffect(() => {
    if (state.city === null && state.model !== null) {
      loadDefaultCity(state.model.invoice_data.city);
    }
  }, [state.model]);

  const handleOrderProductAction = (accept, childId, selectedVariant = null) => {
    setState({
      ...state,
      processingBooking: true,
    });
    const action = accept ? "Confermata" : "Rifiutata";
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({
          ...state,
          processingBooking: false,
          model: formatModel(response),
        });
        resolve();
      };

      const koEditCallback = (response) => {
        setState({
          ...state,
          processingBooking: false,
          error: response?.error,
        });
        reject();
      };

      if (accept) {
        ordersService.acceptOrderProductVariant(
          {
            orderId: state.model["_id"],
            childId,
            selectedVariant,
          },
          okEditCallback,
          koEditCallback
        );
      } else {
        ordersService.rejectOrderProductVariant(
          {
            orderId: state.model["_id"],
            childId,
          },
          okEditCallback,
          koEditCallback
        );
      }
    });

    toast.promise(
      savePromise,
      {
        loading: `Attendere, ${accept ? "confermando" : "rifiutando"} la prenotazione...`,
        success: `Prenotazione ${action} con successo!`,
        error: "Ops, si è verificato un errore!",
      },
      {
        success: {
          duration: 5000,
        },
        error: {
          duration: 5000,
        },
      }
    );
  };

  const checkProductStatus = (productData, contact_confirmed_at) => {
    let badgeColor = "warning";
    let badgeText = "In attesa di approvazione";

    if (productData === "contact_rejected") {
      badgeColor = "danger";
      badgeText = "Prenotazione rifiutata";
    }
    if (productData === "platform_paid") {
      badgeColor = "success";
      badgeText = "Pagato";
    }
    if (productData === "platform_refunded") {
      badgeColor = "danger";
      badgeText = "Acquisto rimborsato";
    }

    if (productData === "contact_confirmed" || contact_confirmed_at) {
      badgeColor = "success";
      badgeText = "Prenotazione approvata";
    }
    if (productData === "deleted") {
      badgeColor = "primary";
      badgeText = "Prenotazione cancellata";
    }
    return (
      <CBadge className="ms-2" color={badgeColor}>
        {badgeText}
      </CBadge>
    );
  };

  const renderApproveButton = (currentProduct, currentVariant) => {
    if (!currentProduct?.status && userGroup !== "editor") {
      return (
        <div className="d-grid gap-2">
          <CButton
            disabled={state.processingBooking}
            color="success"
            onClick={() => handleOrderProductAction(true, currentProduct?.child_id, currentVariant)}
          >
            Conferma
          </CButton>
        </div>
      );
    }
    if (currentProduct?.status === "contact_rejected") {
      return <CIcon icon={cilXCircle} size="xxl" className="text-danger" />;
    }
    if (
      currentProduct?.status === "contact_confirmed" &&
      !!currentProduct?.confirmed_dt &&
      currentProduct?.confirmed_dt === currentVariant
    ) {
      return <CIcon icon={cilCheckCircle} size="xxl" className="text-success" />;
    }
    return null;
  };

  const filterPurchaseOptions = (productData) => {
    const productPurchaseOption = productData.attributes.purchase_options.find(
      (currentPurchaseOption) => currentPurchaseOption.id === productData.purchase_option_id
    );
    if (productPurchaseOption) {
      // return productPurchaseOption.name_tag.label;
      return productPurchaseOption;
    }
    return "N/D";
  };

  const mapPurchaseOptionsIncludedServices = (productData, index) => <li key={index}>{productData}</li>;

  const mapAllergies = (person, index) => (
    <li key={index} className="text-danger">
      <strong>N°1 persona:</strong> {person.allergies.join(", ").toLowerCase()}
    </li>
  );

  const filterSubordersDetails = (productId) => {
    const suborders = state.model.suborders[0];
    if (suborders && suborders.details) {
      const productSuborderDetails = suborders.details.find(
        (currentSuborder) => currentSuborder.product_id === productId
      );
      if (productSuborderDetails) {
        return utils.formatCurrency({
          number: productSuborderDetails.total_amount,
        });
      }
    }
    return "N/D";
  };

  const GetVoucherDetails = ({ voucherData }) => {
    if (voucherData) {
      return (
        <>
          <p>
            <b>Codice voucher: </b>
            {voucherData?.code}
          </p>
          <p>
            <b>Numero ordine voucher: </b>#{voucherData?.order_number}
          </p>
          <p>
            <b>Scadenza voucher: </b>
            {format(new Date(voucherData?.expiring_at), "dd/MM/yyyy") || "N.D."}
          </p>
          <p>
            <b>Valore iniziale voucher: </b>
            {utils.formatCurrency({
              number: voucherData?.initial_value,
            })}
          </p>
          <p>
            <b>Saldo attuale voucher: </b>
            {utils.formatCurrency({
              number: voucherData?.remaining_amount,
            })}
          </p>
        </>
      );
    }
    return null;
  };

  const GetPromoCodeDetails = ({ promoCodeData }) => {
    if (promoCodeData) {
      return promoCodeData.map((promoCode, index) => {
        return (
          <li key={index}>
            {promoCode?.code} (-{promoCode?.discount?.value}
            {promoCode?.discount?.type === "percentage" ? "%" : "€"})
          </li>
        );
      });
    }
    return null;
  };

  if (state?.loading === true) return <AppLoadingSpinner />;

  return (
    <AppDetail
      actions={false}
      name="Dettaglio della prenotazione"
      detail={`Prenotazione #${state?.model?.order_number}`}
      tabsHeaders={
        userGroup === "winery"
          ? [
              {
                index: "tour-tab",
                label: "DATI PRENOTAZIONI",
              },
            ]
          : [
              {
                index: "tour-tab",
                label: "DATI PRENOTAZIONI",
              },
              {
                index: "user-tab",
                label: "DATI UTENTE",
              },
            ]
      }
      tabsContents={[
        {
          index: "user-tab",
          content: (
            <CRow>
              <CCol md={12}>
                <section id="orders-detail">
                  <CForm className="row g-3 mt-3" onSubmit={handleSubmit(onSubmit)}>
                    <CRow className="mb-3">
                      <CCol md={12} sm={12}>
                        <CRow className="mb-3">
                          <CCol>
                            <Controller
                              name="user_first_name"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_first_name}
                                  feedback={
                                    errors?.user_first_name ? composeErrorFormType(errors.user_first_name) : null
                                  }
                                  type="text"
                                  id="order-user_first_name"
                                  label="Nome utente"
                                  placeholder="Nome utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                          <CCol>
                            <Controller
                              name="user_last_name"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_last_name}
                                  feedback={errors?.user_last_name ? composeErrorFormType(errors.user_last_name) : null}
                                  type="text"
                                  id="order-user_last_name"
                                  label="Cognome utente"
                                  placeholder="Cognome utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="mb-3">
                          <CCol>
                            <Controller
                              name="user_address"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_address}
                                  feedback={errors?.user_address ? composeErrorFormType(errors.user_address) : null}
                                  type="text"
                                  id="order-user_address"
                                  label="Indirizzo"
                                  placeholder="Indirizzo utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                          <CCol>
                            <Controller
                              name="user_city"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_city}
                                  feedback={errors?.user_city ? composeErrorFormType(errors.user_city) : null}
                                  type="text"
                                  id="order-user_city"
                                  label="Città"
                                  placeholder="Città utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="mb-3">
                          <CCol>
                            <Controller
                              name="user_state"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_state}
                                  feedback={errors?.user_state ? composeErrorFormType(errors.user_state) : null}
                                  type="text"
                                  id="order-user_state"
                                  label="Provincia"
                                  placeholder="Provincia utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                          <CCol>
                            <Controller
                              name="user_zip_code"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_zip_code}
                                  feedback={errors?.user_zip_code ? composeErrorFormType(errors.user_zip_code) : null}
                                  type="text"
                                  id="order-user_zip_code"
                                  label="CAP"
                                  placeholder="CAP utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="mb-3">
                          <CCol>
                            <Controller
                              name="user_country"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => {
                                return (
                                  <CFormInput
                                    readOnly
                                    invalid={!!errors.user_country}
                                    feedback={errors?.user_country ? composeErrorFormType(errors.user_country) : null}
                                    type="text"
                                    id="order-user_country"
                                    label="Nazione"
                                    placeholder="Nazione utente"
                                    {...field}
                                  />
                                );
                              }}
                            />
                          </CCol>
                          <CCol>
                            <Controller
                              name="user_email"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_email}
                                  feedback={errors?.user_email ? composeErrorFormType(errors.user_email) : null}
                                  type="email"
                                  id="order-user_email"
                                  label="Email utente"
                                  placeholder="Email utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="mb-3">
                          <CCol>
                            <Controller
                              name="user_phone"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_phone}
                                  feedback={errors?.user_phone ? composeErrorFormType(errors.user_phone) : null}
                                  type="text"
                                  id="order-user_phone"
                                  label="Telefono utente"
                                  placeholder="Telefono utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                        </CRow>
                        <CRow className="mb-3">
                          <CCol>
                            <Controller
                              name="user_tax_code"
                              control={control}
                              rules={{ required: true }}
                              defaultValue=""
                              render={({ field }) => (
                                <CFormInput
                                  readOnly
                                  invalid={!!errors.user_tax_code}
                                  feedback={errors?.user_tax_code ? composeErrorFormType(errors.user_tax_code) : null}
                                  type="text"
                                  id="order-user_tax_code"
                                  label="Codice fiscale utente"
                                  placeholder="Codice fiscale utente"
                                  {...field}
                                />
                              )}
                            />
                          </CCol>
                        </CRow>
                      </CCol>
                    </CRow>
                  </CForm>
                </section>
              </CCol>
            </CRow>
          ),
        },
        {
          index: "tour-tab",
          content: (
            <>
              <CRow className="mb-2">
                <CCol>
                  <h2>{`Prenotazione #${state?.model?.order_number}`}</h2>
                </CCol>
              </CRow>
              {state?.model?.products &&
                state?.model?.products.map((currentProduct) => {
                  return (
                    <CRow className="mb-4" key={currentProduct?.name}>
                      <CCol>
                        <h4>{currentProduct?.name}</h4>
                        <CRow>
                          <CCol md={6} sm={12} className="border-right-md">
                            <p>
                              <b>Cantina: </b>
                              {`${currentProduct?.contact?.business_name} - ${currentProduct?.contact?.registered_address}` ||
                                "-"}
                            </p>
                            <p>
                              <b>Numero di persone: </b>
                              {currentProduct?.product_quantity || 0}
                            </p>
                            <p>
                              <b>Lingua tour: </b>
                              {currentProduct?.variants?.language?.translations?.it?.tag ||
                                currentProduct?.variants?.language?.tag ||
                                "-"}
                            </p>
                            <p>
                              <b>Nome della prenotazione: </b>
                              {currentProduct?.contact_confirmed_at
                                ? `${state.model.invoice_data?.first_name} ${state.model.invoice_data?.last_name}`
                                : state.model.invoice_data?.first_name}
                            </p>
                            {/* Phone number hidden for wineries if the order is not confirmed or if it is confirmed but then canceled by the user */}
                            {(userGroup === "superadmin" ||
                              userGroup === "admin" ||
                              (userGroup === "winery" &&
                                currentProduct?.contact_confirmed_at &&
                                !currentProduct?.deleted_at)) && (
                              <p>
                                <b>Telefono: </b>
                                {state?.model?.invoice_data?.phone}
                              </p>
                            )}
                            <p>
                              <b>Pacchetto scelto: </b>
                              {filterPurchaseOptions(currentProduct)?.name_tag?.label || "N.D."}
                            </p>
                            <b>Dettagli pacchetto: </b>
                            <ul className="my-3">
                              {filterPurchaseOptions(currentProduct)?.translations?.it?.included_services?.map(
                                mapPurchaseOptionsIncludedServices
                              )}
                            </ul>
                            {currentProduct?.variants?.allergies?.length > 0 && (
                              <>
                                <b className="text-danger text-decoration-underline">Allergie ed intolleranze: </b>
                                <ul className="my-3">{currentProduct?.variants?.allergies.map(mapAllergies)}</ul>
                              </>
                            )}
                            <p>
                              <b>{`Prezzo netto per ${currentProduct?.contact_name} pacchetto a persona:`} </b>
                              {utils.formatCurrency({
                                number: currentProduct?.amounts?.winery / currentProduct?.product_quantity,
                              })}
                            </p>
                            {console.log("currentProduct", currentProduct)}
                            {userGroup !== "editor" && userGroup !== "winery" && (
                              <p>
                                <b>Fee Vinbacco: </b>
                                {utils.formatCurrency({
                                  number: currentProduct?.amounts?.vinbacco,
                                })}
                              </p>
                            )}
                            {userGroup !== "winery" && (
                              <p>
                                <b>Fee Cantina: </b>
                                {utils.formatCurrency({
                                  number: currentProduct?.amounts?.winery,
                                })}
                              </p>
                            )}
                            <p>
                              <b>Totale{userGroup === "winery" && ` pacchetto`}: </b>
                              {userGroup === "winery"
                                ? utils.formatCurrency({
                                    number: currentProduct?.amounts?.winery,
                                  })
                                : filterSubordersDetails(currentProduct?._id)}
                            </p>
                            {userGroup !== "winery" && (
                              <>
                                <p>
                                  <b>Importo pagato con voucher: </b>
                                  {currentProduct?.amounts?.specialVoucher
                                    ? "utilizzato Voucher Speciale"
                                    : currentProduct?.amounts?.voucher
                                    ? utils.formatCurrency({
                                        number: currentProduct?.amounts?.voucher,
                                      })
                                    : "voucher non utilizzato"}
                                </p>
                                {/* We show the voucher details only if the user is a superadmin
                                 and in this place only if the order contains 1 tour, in case of 
                                 multi tour order we show the recap at the end of the page */}
                                {userGroup === "superadmin" &&
                                  state?.model?.voucher_data &&
                                  state.model?.products.length === 1 && (
                                    <GetVoucherDetails voucherData={state.model?.voucher_data} />
                                  )}
                                <p>
                                  <b>Importo pagato con cachet: </b>
                                  {currentProduct?.amounts?.wallet
                                    ? utils.formatCurrency({
                                        number: currentProduct?.amounts?.wallet,
                                      })
                                    : "cachet non utilizzato"}
                                </p>
                                <p>
                                  <b>Sconto derivante da codici promo: </b>
                                  {currentProduct?.amounts?.promoCodes
                                    ? utils.formatCurrency({
                                        number: currentProduct?.amounts?.promoCodes,
                                      })
                                    : "codice sconto non utilizzato"}
                                </p>
                                {/* We show the promo code details only if the user is a superadmin
                                 and in this place only if the order contains 1 tour, in case of 
                                 multi tour order we show the recap at the end of the page */}
                                {userGroup === "superadmin" &&
                                  state?.model?.promo_code &&
                                  state.model?.products.length === 1 && (
                                    <>
                                      <b>
                                        {state?.model?.promo_code?.length > 1
                                          ? "Codici promozionali utilizzati: "
                                          : "Codice promozionale utilizzato: "}
                                      </b>
                                      <ul className="my-3">
                                        <GetPromoCodeDetails promoCodeData={state.model?.promo_code} />
                                      </ul>
                                    </>
                                  )}
                              </>
                            )}
                            {userGroup == "winery" && (
                              <>
                                <p>
                                  <b>Importo che paga il cliente in cantina: </b>
                                  {utils.formatCurrency({
                                    number: currentProduct?.amounts?.customerToWinery,
                                  })}
                                </p>
                                <p>
                                  <b>Importo che paga Vinbacco con fattura: </b>
                                  {utils.formatCurrency({
                                    number: currentProduct?.amounts?.vinbaccoToWinery,
                                  })}
                                </p>
                              </>
                            )}

                            {/* 
                              stripe_remaining and winery_remaining are not available from the API if the order is not 
                              paid via voucher or wallet, so we need to check if they are available before showing them
                            */}
                            {userGroup !== "editor" &&
                              userGroup !== "winery" &&
                              (currentProduct?.amount_paid_with_voucher ||
                                currentProduct?.amount_paid_with_voucher === 0 ||
                                currentProduct?.amount_paid_with_wallet ||
                                currentProduct?.amount_paid_with_wallet === 0) && (
                                <p>
                                  <b>Restante da pagare a Vinbacco: </b>
                                  {currentProduct?.amounts?.customerToVinbacco ||
                                  currentProduct?.amounts?.customerToVinbacco === 0 ? (
                                    <>
                                      {utils.formatCurrency({
                                        number: currentProduct?.amounts?.customerToVinbacco,
                                      })}
                                    </>
                                  ) : (
                                    "N.D."
                                  )}
                                </p>
                              )}
                            {userGroup !== "winery" &&
                              (currentProduct?.amount_paid_with_voucher ||
                                currentProduct?.amount_paid_with_voucher === 0 ||
                                currentProduct?.amount_paid_with_wallet ||
                                currentProduct?.amount_paid_with_wallet === 0 ||
                                currentProduct?.amount_paid_with_promo_code ||
                                currentProduct?.amount_paid_with_promo_code === 0) && (
                                <p>
                                  <b>Restante da pagare in Cantina: </b>
                                  {currentProduct?.amounts?.customerToWinery ||
                                  currentProduct?.amounts?.customerToWinery === 0 ? (
                                    <>
                                      {utils.formatCurrency({
                                        number: currentProduct?.amounts?.customerToWinery,
                                      })}
                                    </>
                                  ) : (
                                    "N.D."
                                  )}
                                </p>
                              )}
                            {userGroup === "superadmin" && (
                              <p>
                                <b>Totale che spetta alla Cantina da parte di Vinbacco: </b>
                                {utils.formatCurrency({
                                  number: currentProduct?.amounts?.vinbaccoToWinery,
                                })}
                              </p>
                            )}
                          </CCol>
                          <CCol md={6} sm={12}>
                            <h6 className="mb-4">
                              {"Stato della prenotazione: "}
                              {checkProductStatus(currentProduct?.status, currentProduct?.contact_confirmed_at)}
                            </h6>
                            {currentProduct?.contact_confirmed_at && (
                              <p>
                                <b>Data e orario confermato: </b>
                                {formatItalianDateWithTime(currentProduct?.confirmed_dt)}
                              </p>
                            )}
                            {!currentProduct?.confirmed_dt && (
                              <>
                                <h6 className="mb-4">
                                  Orari scelti{" "}
                                  {!(currentProduct?.status === "contact_rejected") && (
                                    <>(potrai selezionare solamente 1 orario tra quelli proposti)</>
                                  )}
                                </h6>

                                {currentProduct?.variants &&
                                  currentProduct?.variants?.datetimes &&
                                  currentProduct?.variants?.datetimes.map((currentVariant, index, array) => {
                                    const currentDate = getItalianZonedDate(currentVariant);
                                    const previousDate = index > 0 ? getItalianZonedDate(array[index - 1]) : null;

                                    const isSame = previousDate && isSameDay(previousDate, currentDate);

                                    return (
                                      <div key={index}>
                                        {index > 0 && !isSame && <hr key={`separator-${index}`} className="my-4" />}
                                        <CRow className="mb-4 align-items-center" key={currentVariant}>
                                          <CCol md={6}>{formatItalianDateWithTime(currentVariant)}</CCol>
                                          <CCol md={6}>{renderApproveButton(currentProduct, currentVariant)}</CCol>
                                        </CRow>
                                      </div>
                                    );
                                  })}
                              </>
                            )}

                            {!currentProduct?.status && <hr />}
                            <CRow className="mb-4">
                              <CCol md={{ span: 6, offset: 6 }}>
                                {!currentProduct?.status && userGroup !== "editor" && (
                                  <div className="d-grid gap-2">
                                    <CButton
                                      disabled={state.processingBooking}
                                      color="danger"
                                      onClick={() => handleOrderProductAction(false, currentProduct?.child_id)}
                                    >
                                      Rifiuta
                                    </CButton>
                                  </div>
                                )}
                              </CCol>
                            </CRow>
                          </CCol>
                        </CRow>
                        <hr />
                      </CCol>
                    </CRow>
                  );
                })}
              {(state.model?.products.length > 1 ||
                (state.model?.products.length === 1 && state.model?.products[0].deleted_at)) && ( // We show the recap only if there are more than 1 tour booked or if there is only 1 tour booked but it has been deleted
                <CRow>
                  <CCol>
                    <p>
                      <b>Totale prenotazione: </b>
                      {utils.formatCurrency({
                        number: state.model?.total_amount,
                      }) || "N.D."}
                    </p>
                    <p>
                      <b>Utilizzo voucher: </b>
                      {utils.formatCurrency({
                        number: state.model?.products.reduce(
                          (acc, currentProduct) =>
                            acc + (currentProduct?.deleted_at ? 0 : currentProduct?.amounts?.voucher),
                          0
                        ),
                      })}
                    </p>
                    {userGroup === "superadmin" && state?.model?.voucher_data && (
                      <GetVoucherDetails voucherData={state.model?.voucher_data} />
                    )}
                    <p>
                      <b>Utilizzo cachet: </b>
                      {utils.formatCurrency({
                        number: state.model?.products.reduce((acc, currentProduct) => {
                          return acc + (currentProduct?.deleted_at ? 0 : currentProduct?.amounts?.wallet);
                        }, 0),
                      })}
                    </p>
                    {(userGroup === "superadmin" || userGroup === "admin") && (
                      <p>
                        <b>Sconto totale da codici promo: </b>
                        {utils.formatCurrency({
                          number: state.model?.promo_code_use_amount || 0,
                        })}
                        {state.model?.promo_code_use_amount > 0 && state.model?.promo_code && (
                          <ul className="my-3">
                            <GetPromoCodeDetails promoCodeData={state.model?.promo_code} />
                          </ul>
                        )}
                      </p>
                    )}

                    {userGroup !== "editor" && userGroup !== "winery" && (
                      <p>
                        <b>Restante da pagare a Vinbacco: </b>
                        {utils.formatCurrency({
                          number: state.model?.remaining_to_pay_on_stripe,
                        }) || "N.D."}
                      </p>
                    )}
                    <p>
                      <b>Restante da pagare alle cantine: </b>
                      {utils.formatCurrency({
                        number: state.model?.remaining_to_pay_in_winery,
                      }) || "N.D."}
                    </p>
                  </CCol>
                </CRow>
              )}
            </>
          ),
        },
      ]}
    />
  );
};

export default OrdersDetail;
