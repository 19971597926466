import ApiProxyService from './apiProxyService';

export default class SponsoredToursService extends ApiProxyService {
  BASE_PATH = 'sponsored-tours';

  getTours(nameFilter, okCallback, koCallback) {
    const path = `/products/filter-for-sponsored${
      nameFilter ? `???_^name=${nameFilter}` : ''
    }`;
    super.getItem(path, okCallback, koCallback);
  }

  getSponsoredTours(okCallback, koCallback) {
    const path = `/products/${this.BASE_PATH}`;
    super.getItem(path, okCallback, koCallback);
  }

  saveSponsoredTours(body, okCallback, koCallback) {
    const path = '/products/set-sponsored-order';
    super.updateItem(path, body, okCallback, koCallback);
  }
}
