/* eslint-disable no-underscore-dangle */
/* eslint-disable dot-notation */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import {
  CForm,
  CRow,
  CCol,
  CFormInput,
  CFormLabel,
  CButton,
  CFormSelect,
} from '@coreui/react';
// SERVICES
import TagsService from 'src/services/api/TagsService';
import FeedsService from 'src/services/api/FeedsService';
import AppBaseDetail from 'src/components/ui/Detail/AppBaseDetail';
import AppLoadingSpinner from 'src/components/ui/AppLoadingSpinner';
import ImageWithPreview from 'src/components/ui/Images/ImageWithPreview';
// Utils
import { LANGUAGE_OPTIONS } from 'src/utils/translationUtils';
import useTranslationBatch from 'src/hooks/useTranslationBatch';
import TranslationBatchModal from 'src/components/ui/TranslationBatchModal';
import TranslationStatus from 'src/components/ui/TranslationStatus/TranslationStatus';
import composeErrorFormType from 'src/utils/composeErrorFormType';

const TagsDetail = () => {
  const { id } = useParams();
  const [tagPreviewImage, setTagPreviewImage] = useState(null);
  const {
    control,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm();
  const [state, setState] = useState({
    loading: true,
    model: null,
  });
  const [currentLanguage, setCurrentLanguage] = useState('it');

  const tagsService = new TagsService();

  const {
    translationModal,
    selectedLanguages,
    setSelectedLanguages,
    handleTranslate,
    executeTranslation,
    closeTranslationModal,
    resetSessionTranslations,
  } = useTranslationBatch({
    type: 'tags',
    originalModel: state.model,
    batchSize: 5,
    onTranslationComplete: (translations) => {
      const currentFormValues = getValues();
      currentFormValues.translations = translations;
      reset(currentFormValues);
    },
  });

  const loadFeeds = (filter) => new Promise((resolve) => {
    const feedsService = new FeedsService();
    const okCallback = (response) => {
      let responseData = [];
      if (Array.isArray(response.data) && response.data.length > 0) {
        responseData = response.data.map((currentItem) => (
          { value: currentItem['_id'], label: currentItem.name }
        ));
      }
      resolve(responseData);
    };
    const koCallback = () => resolve([]);
    const filters = {};
    if (filter.length > 0) filters.search = filter;
    feedsService.getList({
      paginate: 5,
      page: 1,
      filters,
      okCallback: (res) => okCallback(res),
      koCallback: (err) => koCallback(err),
    });
  });

  const handleReset = () => {
    reset({ ...state.model });
  };

  const formatModel = (response) => {
    const responseData = { ...response.data };
    let feedCurrent = null;
    if (response.data?.feed?._id && response.data?.feed?.name) {
      feedCurrent = { value: response.data.feed._id, label: response.data.feed.name };
    }

    if (feedCurrent !== null) responseData.feed_id = feedCurrent;

    if (Array.isArray(responseData.media_contents)) {
      const tagImage = responseData.media_contents.find((current) => current.type === 'tag_image');
      if (tagImage !== null || typeof tagImage !== 'undefined') setTagPreviewImage(tagImage);
    }

    if (!responseData.translations) {
      responseData.translations = {
        it: { tag: responseData.tag || '' },
      };
    }

    return responseData;
  };

  const onSubmit = (data) => {
    const savePromise = new Promise((resolve, reject) => {
      const okEditCallback = (response) => {
        setState({ loading: false, model: formatModel(response) });
        // Reset delle traduzioni in memoria dopo il salvataggio riuscito
        resetSessionTranslations();
        resolve();
      };

      const koEditCallback = (response) => {
        setState({ loading: false, error: response?.error });
        reject();
      };

      // Prepariamo i dati da salvare
      const dataToSave = {
        ...data,
        tag: data.translations?.it?.tag, // Il campo principale prende il valore italiano
        translations: data.translations, // Manteniamo tutte le traduzioni
      };

      tagsService.updateItem(state.model['_id'], dataToSave, okEditCallback, koEditCallback);
    });

    toast.promise(savePromise, {
      loading: 'Attendere, salvando le modifiche...',
      success: 'Dato modificato con successo!',
      error: 'Ops, si è verificato un errore!',
    }, {
      success: {
        duration: 5000,
      },
      error: {
        duration: 5000,
      },
    });
  };

  const onChangeTagPreviewImage = (response) => {
    if (response.job === 'delete') return setTagPreviewImage(null);
    return setTagPreviewImage(response.response.data);
  };

  useEffect(() => {
    if (id && state.loading) {
      const okGetCallback = (response) => {
        const responseData = formatModel(response);
        const modelData = {
          ...responseData,
          translations: responseData.translations || {
            it: { tag: responseData.tag || '' },
          },
        };

        // Create empty structures for all supported languages
        const supportedLanguages = LANGUAGE_OPTIONS.map((option) => option.value);
        const completeTranslations = { ...modelData.translations };

        // Ensure all languages have at least empty structures
        supportedLanguages.forEach((lang) => {
          if (!completeTranslations[lang]) {
            completeTranslations[lang] = { tag: '' };
          }
        });

        // Update the model with complete translations
        modelData.translations = completeTranslations;

        reset({
          color: responseData.color,
          feed_id: responseData.feed_id,
          translations: completeTranslations,
        });

        setState({ loading: false, model: modelData });
      };

      const koGetCallback = (error) => {
        const errorMessage = error?.data?.message || 'Nessun errore';
        setState({ loading: false, error: errorMessage });
        throw new Error(errorMessage);
      };

      tagsService.getItem(id, okGetCallback, koGetCallback);
    } else if (state.model && !state.loading) {
      const currentValues = getValues();
      reset({
        ...currentValues,
        translations: currentValues.translations,
      });
    }
  }, [id, currentLanguage, state.loading, state.model]);

  if (state?.loading === true) return <AppLoadingSpinner />;

  return (
    <AppBaseDetail
      type="tags"
      saveAction={handleSubmit(onSubmit)}
      resetAction={handleReset}
    >
      <section id="tags-detail">
        <CFormSelect
          className="mb-4"
          value={currentLanguage}
          onChange={(e) => setCurrentLanguage(e.target.value)}
          options={LANGUAGE_OPTIONS}
          label="Lingua"
        />
        <div className="d-flex justify-content-start align-items-center mb-4 gap-5">
          {currentLanguage === 'it' && (
            <CButton
              color="primary"
              size="sm"
              onClick={() => handleTranslate({
                tag: getValues('translations.it.tag'),
              })}
            >
              Traduci automaticamente
            </CButton>
          )}
          <TranslationStatus
            translations={getValues('translations')}
            fields={['tag']}
          />
        </div>
        <CForm
          className="row g-3 mt-3"
          onSubmit={handleSubmit(onSubmit)}
        >
          <CRow className="mb-3">
            <CCol md={8} sm={12}>
              <CRow className="mb-3">
                <CCol>
                  <Controller
                    name="feed_id"
                    control={control}
                    rules={{ required: true }}
                    defaultValue={state?.model?.feed_id || null}
                    render={({ field }) => (
                      <>
                        <CFormLabel htmlFor="feed_id">Feed</CFormLabel>
                        <AsyncSelect
                          inputId="feed_id"
                          isClearable
                          defaultOptions
                          loadOptions={loadFeeds}
                          {...field}
                        />
                        {errors.feed_id ? <div className="invalid-feedback d-block">{composeErrorFormType(errors.feed_id)}</div> : null}
                      </>
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol>
                  <Controller
                    name={`translations.${currentLanguage}.tag`}
                    control={control}
                    defaultValue={state?.model?.translations?.[currentLanguage]?.tag || ''}
                    render={({ field }) => (
                      <CFormInput
                        label="Nome tag"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
              <CRow className="mb-3">
                <CCol>
                  <Controller
                    name="color"
                    defaultValue="#ffffff"
                    control={control}
                    render={({ field }) => (
                      <CFormInput
                        label="Colore tag"
                        {...field}
                      />
                    )}
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md={4} sm={12}>
              <ImageWithPreview
                contentId={id}
                contentType="tag_image"
                Service={TagsService}
                title="Immagine tag"
                data={tagPreviewImage}
                onUpdate={(data) => onChangeTagPreviewImage(data)}
              />
            </CCol>
          </CRow>
        </CForm>
        <TranslationBatchModal
          show={translationModal.show}
          selectedLanguages={selectedLanguages}
          onLanguageSelect={setSelectedLanguages}
          onClose={closeTranslationModal}
          onStartTranslation={executeTranslation}
          batchProgress={translationModal.progress}
          currentBatch={translationModal.currentBatch}
          totalBatches={translationModal.totalBatches}
          translationStatus={translationModal.translationStatus}
          isTranslating={translationModal.isTranslating}
          currentBatchLanguages={translationModal.currentBatchLanguages}
        />
      </section>
    </AppBaseDetail>
  );
};

export default TagsDetail;
