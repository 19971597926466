/* eslint-disable */
import React, { useEffect} from "react";
import PropTypes from "prop-types";
import { CBadge, CTooltip } from "@coreui/react";
import { SUPPORTED_LANGUAGES } from "src/utils/translationUtils";

function TranslationStatus({ translations, fields }) {
  const targetLanguages = SUPPORTED_LANGUAGES.filter((lang) => lang !== "it");

  // Helper to check if a field has content
  const hasContent = (value) => {
    return value && (Array.isArray(value) ? value.length > 0 : value.trim() !== "");
  };

  const getTranslationStatus = (lang) => {
    if (!translations || !translations[lang]) {
      return "missing";
    }

    // Get fields that actually have content in Italian (our reference language)
    const italianFieldsWithContent = fields.filter((field) => {
      return translations.it && hasContent(translations.it[field]);
    });

    // If Italian has no content for any fields, check if this language has any content
    if (italianFieldsWithContent.length === 0) {
      const hasAnyContent = fields.some(field => hasContent(translations[lang][field]));
      return hasAnyContent ? "complete" : "missing";
    }

    // Check which fields from Italian are translated in this language
    const translatedFields = italianFieldsWithContent.filter((field) => {
      return hasContent(translations[lang][field]);
    });

    if (translatedFields.length === 0) return "missing";
    if (translatedFields.length === italianFieldsWithContent.length) return "complete";
    return "partial";
  };

  const getBadgeColor = (status) => {
    switch (status) {
      case "complete":
        return "success";
      case "partial":
        return "warning";
      case "missing":
      default:
        return "danger";
    }
  };

  const getTooltipContent = (lang) => {
    const status = getTranslationStatus(lang);
    const langName = new Intl.DisplayNames(["it"], { type: "language" }).of(
      lang
    );

    if (status === "missing") {
      return `${langName}: nessuna traduzione presente`;
    }

    // Get fields that have content in Italian
    const italianFieldsWithContent = fields.filter((field) => {
      return translations.it && hasContent(translations.it[field]);
    });

    // If Italian has no content, check which fields have content in this language
    let translatedFields = [];
    let missingFields = [];
    
    if (italianFieldsWithContent.length === 0) {
      translatedFields = fields.filter(field => hasContent(translations[lang][field]));
    } else {
      translatedFields = italianFieldsWithContent.filter((field) => {
        return hasContent(translations[lang][field]);
      });
      missingFields = italianFieldsWithContent.filter(
        (field) => !translatedFields.includes(field)
      );
    }

    const translatedContent = translatedFields
      .map((field) => `✓ ${field}`)
      .join("\n");
    const missingContent = missingFields
      .map((field) => `✗ ${field}`)
      .join("\n");

    return (
      `${langName}:\n` +
      (translatedContent ? `${translatedContent}\n` : "") +
      (missingContent ? `${missingContent}` : "")
    );
  };

  return (
    <div className="d-flex gap-2 align-items-center overflow-auto">
      {targetLanguages.map((lang) => (
        <CTooltip
          key={lang}
          content={getTooltipContent(lang)}
          placement="top"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <CBadge
            color={getBadgeColor(getTranslationStatus(lang))}
            className="text-uppercase mb-2"
            style={{ cursor: "help" }}
          >
            {lang.substring(0, 2)}
          </CBadge>
        </CTooltip>
      ))}
    </div>
  );
}

TranslationStatus.propTypes = {
  translations: PropTypes.object.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TranslationStatus;
